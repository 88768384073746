const data = {
  loadingPage: false,
};

export const getters = {
  loadingPage: (state) => state.loadingPage,
};

export const mutations = {
  loadingTrue: (state) => {
    state.loadingPage = true;
  },
  loadingFalse: (state) => {
    state.loadingPage = false;
  },
};
export const actions = {
  loadingTrue({ commit }) {
    commit('loadingTrue');
  },
  loadingFalse({ commit }) {
    commit('loadingFalse');
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
