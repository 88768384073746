<template>
  <div class="glogin__div">
    <GoogleLogin :params="params" class="glogin__btn" :onSuccess="onSuccess" :onFailure="onFailure"
      ><img :src="glogo" class="glogin__btn-logo" alt="google logo" /><span class="glogin__btn-txt"
        >Sign in with Google</span
      ></GoogleLogin
    >
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapGetters } from "vuex";
import axios from "axios";
import { GoogleLogin } from "vue-google-login";

export default {
  name: "Login",
  data() {
    return {
      params: {
        client_id: this.client_id
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true
      },

      // image
      glogo: "/img/logo/google.svg"
    };
  },
  methods: {
    onSuccess(googleUser) {
      this.$store.dispatch("loadingTrue");
      const profile = googleUser.getBasicProfile();
      this.submitEmail(profile.getEmail());
    },
    onFailure(error) {
      // console.log(error);
    },
    routeToPage(pageStr) {
      this.$router.push({ name: pageStr });
    },
    submitEmail(emailStr) {
      const request = {
        email: emailStr
      };
      // this.$store.dispatch('setRole', '');

      // add skipAuthRefresh flag to skip interceptor
      axios
        .post(this.adminloginapi, request, { skipAuthRefresh: true })
        .then(
          res => {
            // console.log(res.status);
            // console.log(res);
            const { role } = res.data.result;
            // save admin role to vuex
            // console.log('role', role);
            this.$store.dispatch("setRole", role);
            if (role === "admin") {
              if (res.data.result.auth) {
                this.$store.dispatch("setUserAuth", {
                  access_token: res.data.result.auth.access_token,
                  refresh_token: res.data.result.auth.refresh_token
                });
              }
              this.routeToPage("admin-home");
            } else {
              this.routeToPage("admin-login-forbidden");
            }
          },
          error => {
            console.log(error);
            if (error.response.status === 403) {
              // console.log('submit email error');
              this.routeToPage("admin-login-forbidden");
            } else {
              this.routeToPage("admin-failed");
              // console.log('submit employee error');
            }
          }
        )
        .catch(error => {
          // console.log('catch error: ');
          console.log(error);
          this.routeToPage("admin-failed");
        });
    }
  },
  computed: {
    ...mapGetters(["adminloginapi", "client_id"])
  },
  components: {
    GoogleLogin
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
