import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import applocalstorage from './applocalstorage';
import store from '../store';
import router from '../router';

// Service for storing data to localstorage
const localStorageService = applocalstorage.getService();

// API urls
const apiUrl = {
  API_BASE_URL: process.env.VUE_APP_API_URL,
  // DEV_API_BASE_URL: 'https://gdee84my1k.execute-api.ap-southeast-1.amazonaws.com/dev',
};

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => axios.get(store.getters.refreshtokenapi, {
  // headers: {
  //   Authorization: `Bearer ${localStorageService.getRefreshToken}`,
  // },
}).then((tokenRefreshResponse) => {
  const req = failedRequest;
  const reftoken = localStorageService.getRefreshToken();
  if (tokenRefreshResponse.data.result.auth) {
    store.dispatch('setUserAuth', {
      access_token: tokenRefreshResponse.data.result.auth.access_token,
      refresh_token: tokenRefreshResponse.data.result.auth.refresh_token,
    });
  }
  req.response.config.headers.Authorization = `Bearer ${reftoken}`;
  // console.log('tokenRefreshResponse: ', req);
  return Promise.resolve();
}, (error) => {
  // console.log(error);
  if (store.getters.adminRole && store.getters.adminRole === 'admin') {
    // console.log('show login of role: ', store.getters.adminRole);
    store.dispatch('googleSignOut');
    router.push({
      name: 'admin-login',
    });
  } else {
    // Reset user login data
    store.dispatch('resetUserAuth');
    // clear user profile data
    store.dispatch('resetUserInfo');
    store.dispatch('resetUserProfile');
    router.push({
      name: 'user-login',
    });
  }
  return Promise.reject(error);
});

// Used in main.js
export default function setup() {
  // Set api base url
  axios.defaults.baseURL = apiUrl.API_BASE_URL;

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken();
      const reftoken = localStorageService.getRefreshToken();
      const newConfig = config;
      if (!newConfig.url.includes('https://area917-dev.s3.ap-southeast-2.amazonaws.com') && !newConfig.url.includes('https://area917.s3.ap-southeast-1.amazonaws.com')) {
        if (newConfig.url.includes('get_access_token') && reftoken) {
          newConfig.headers.Authorization = `Bearer ${reftoken}`;
        } else if (token) {
          newConfig.headers.Authorization = `Bearer ${token}`;
        }
        // newConfig.headers['Set-Cookie'] = 'HttpOnly;Secure;SameSite=Strict';
        // newConfig.headers['Access-Control-Allow-Origin'] = '*';
      // newConfig.headers['Content-Type'] = 'application/json';
      }
      // console.log('Starting Request', newConfig);
      return newConfig;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  // Add a response interceptor
  /*
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;
      console.log('Error intercepted!', status);
      const originalRequest = error.config;
      if (status === 401) {
        // eslint-disable-next-line no-unused-vars
        return AuthService.refresh((_) => {
          originalRequest.headers.Authorization = `Bearer ${localStorageService.getAccessToken}`;
          originalRequest.baseURL = undefined;
          // Because of loop from the mock server!!!
          originalRequest.url = 'https://demo0562180.mockable.io/hello/world/success/';
          console.log('Request re-building success');
          return axios.request(error.config);
        });
      }

      console.log('Failed to re-build the request');
      return Promise.reject(error);
    },
  );
*/

  /* axios.interceptors.response.use(
    (response) => response, (error) => {
      console.log('Error intercepted!');
      const status = error.response ? error.response.status : null;
      const originalRequest = error.config;

      if (status === 401) {
        return AuthService.refresh(() => {
          originalRequest.headers.Authorization = `Bearer ${localStorageService.getAccessToken}`;
          originalRequest.baseURL = undefined;
          // Because of loop from the mock server!!!
          originalRequest.url = 'http://localhost:4000/user-login';
          console.log('Request re-building success');
          return axios.request(error.config);
        });
      }

      console.log('Failed to re-build the request');
      return Promise.reject(error);

      /*
        originalRequest.retry = true;
        return axios.post('http://localhost:4000/user-login',
          {
            refresh_token: localStorageService.getRefreshToken(),
          })
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to LocalStorage

              // localStorageService.setToken(res.data);
              console.log(res);
              if (res.data.success) {
                if (res.data.auth) {
                  store.commit('userAuth/access_token', res.data.auth.access_token);
                  store.commit('userAuth/refresh_token', res.data.auth.refresh_token);

                  store.commit('authState/setRefreshingState', false);
                  store.commit('authState/setRefreshingCall', null);
                }
              } else {
                store.commit('userAuth/access_token', '');
                store.commit('userAuth/refresh_token', '');
              }

              // 2) Change Authorization header
              axios.defaults.headers.common.Authorization = `Bearer ${localStorageService.getAccessToken()}`;

              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
            store.commit('userAuth/access_token', '');
            store.commit('userAuth/refresh_token', '');
            return Promise.reject(error);
          });
          */
  //   },
  // );


  /* axios.interceptors.response.use((response) => response, (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === 'http://13.232.130.60:8081/v1/auth/token') {
      // TODO router.push('/login');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return axios.post('/auth/token',
        {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorageService.setToken(res.data);
            axios.defaults.headers.common.Authorization = `Bearer ${localStorageService.getAccessToken()}`;
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }); */
}
