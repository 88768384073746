const data = {
  client_id: process.env.VUE_APP_GOOGLE_API,
};

export const getters = {
  client_id: (state) => state.client_id,
};

export default {
  state: data,
  getters,
};
