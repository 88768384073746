import axios from "axios";
import router from "../../router";

const data = {
  requirements: {},
  requirementstatus: true,
  req_user_id: "",
  eis: {},
  eis_have_status: "",
  dependents: [
    {
      name: "",
      relationship: "",
      birthdate: "",
      age: "",
    },
    {
      name: "",
      relationship: "",
      birthdate: "",
      age: "",
    },
    {
      name: "",
      relationship: "",
      birthdate: "",
      age: "",
    },
  ],
  work_experience: [
    {
      company_name: "",
      last_position_held: "",
      inclusive_start_date: "",
      inclusive_end_date: "",
      immediate_supervisor: "",
      reason_for_leaving: "",
    },
    {
      company_name: "",
      last_position_held: "",
      inclusive_start_date: "",
      inclusive_end_date: "",
      immediate_supervisor: "",
      reason_for_leaving: "",
    },
    {
      company_name: "",
      last_position_held: "",
      inclusive_start_date: "",
      inclusive_end_date: "",
      immediate_supervisor: "",
      reason_for_leaving: "",
    },
  ],
  character_reference: [
    {
      name: "",
      relationship: "",
      company: "",
      contact_number: "",
    },
    {
      name: "",
      relationship: "",
      company: "",
      contact_number: "",
    },
    {
      name: "",
      relationship: "",
      company: "",
      contact_number: "",
    },
  ],
};

export const getters = {
  requirements: (state) => state.requirements,
  requirementstatus: (state) => state.requirementstatus,
  eis: (state) => state.eis,
  eis_have_status: (state) => state.eis_have_status,
  dependents: (state) => state.dependents,
  work_experience: (state) => state.work_experience,
  character_reference: (state) => state.character_reference,
  req_user_id: (state) => state.req_user_id,
};

export const mutations = {
  setRequirements: (state, payload) => {
    state.requirements = payload;
    // set EIS status
    if (payload[0].status === 2) {
      state.eis_have_status = false; // enabled
    } else if (payload[0].have_status === 0) {
      state.eis_have_status = false;
    } else {
      state.eis_have_status = true; // disabled
    }

    localStorage.setItem("previous", JSON.stringify(state.requirements));
  },
  resetRequirements: (state) => {
    state.requirements = {};
    state.requirementstatus = true;
  },
  setRequirementStatus: (state, payload) => {
    state.requirementstatus = payload;
  },
  setEis: (state, payload) => {
    state.eis = payload;

    // set dependents
    if (payload.dependents && payload.dependents.name) {
      state.dependents = payload.dependents;
    }

    // set work experience
    if (payload.work_experience && payload.work_experience.company_name) {
      state.work_experience = payload.work_experience;
    }

    // set character reference
    if (payload.character_reference && payload.character_reference.name) {
      state.character_reference = payload.character_reference;
    }
  },
  setDependents: (state, payload) => {
    state.dependents = payload;
  },
  setWorkExperience: (state, payload) => {
    state.work_experience = payload;
  },
  setCharacterReference: (state, payload) => {
    state.character_reference = payload;
  },
  resetDependents: (state) => {
    state.dependents = [
      {
        name: "",
        relationship: "",
        birthdate: "",
        age: "",
      },
      {
        name: "",
        relationship: "",
        birthdate: "",
        age: "",
      },
      {
        name: "",
        relationship: "",
        birthdate: "",
        age: "",
      },
    ];
  },
  resetWorkExperience: (state) => {
    state.work_experience = [
      {
        company_name: "",
        last_position_held: "",
        inclusive_start_date: "",
        inclusive_end_date: "",
        immediate_supervisor: "",
        reason_for_leaving: "",
      },
      {
        company_name: "",
        last_position_held: "",
        inclusive_start_date: "",
        inclusive_end_date: "",
        immediate_supervisor: "",
        reason_for_leaving: "",
      },
      {
        company_name: "",
        last_position_held: "",
        inclusive_start_date: "",
        inclusive_end_date: "",
        immediate_supervisor: "",
        reason_for_leaving: "",
      },
    ];
  },
  resetCharacterReference: (state) => {
    state.character_reference = [
      {
        name: "",
        relationship: "",
        company: "",
        contact_number: "",
      },
      {
        name: "",
        relationship: "",
        company: "",
        contact_number: "",
      },
      {
        name: "",
        relationship: "",
        company: "",
        contact_number: "",
      },
    ];
  },

  resetEis: (state) => {
    state.eis = {};
  },
  setReqUserId: (state, payload) => {
    state.req_user_id = payload;
  },
  resetReqUserId: (state, payload) => {
    state.req_user_id = "";
  },
};

export const actions = {
  // pass request and prepare to submit data
  setRequirements({ commit }, payload) {
    commit("setRequirements", payload);
  },

  setDependents({ commit }, payload) {
    commit("setDependents", payload);
  },

  setWorkExperience({ commit }, payload) {
    commit("setWorkExperience", payload);
  },

  setCharacterReference({ commit }, payload) {
    commit("setCharacterReference", payload);
  },

  resetDependents({ commit }) {
    commit("resetDependents");
  },

  resetWorkExperience({ commit }) {
    commit("resetWorkExperience");
  },

  resetCharacterReference({ commit }) {
    commit("resetCharacterReference");
  },

  // set Req User Id
  setReqUserId({ commit }, payload) {
    commit("setReqUserId", payload);
  },

  resetReqUserId({ commit }) {
    commit("resetReqUserId");
  },

  // pass request and prepare to submit data
  resetRequirements({ commit }) {
    commit("resetRequirements");
  },

  // pass request and prepare to submit data
  setEis({ commit }, payload) {
    commit("setEis", payload);
  },

  // pass request and prepare to submit data
  resetEis({ commit }) {
    commit("resetEis");
  },

  // pass request and prepare to submit data
  setRequirementStatus({ commit }, payload) {
    commit("setRequirementStatus", payload);
  },

  // call requirements api - wait to finish
  callRequirementApi({ dispatch, rootState }, payload) {
    dispatch("loadingTrue");
    dispatch("resetReqUserId");
    dispatch("resetRequirements");
    dispatch("resetEis");
    dispatch("resetDependents");
    dispatch("resetWorkExperience");
    dispatch("resetCharacterReference");
    // actual submission of data
    return axios.post(rootState.services.getrequirementsapi, payload).then(
      (res) => {
        if (res.data.success) {
          // check if success
          if (res.data.result.requirements) {
            // has requirements
            dispatch("setReqUserId", res.data.result.requirements[0].user_id);
            dispatch("setRequirements", res.data.result.requirements);
            // this.requirementstatus = true;
            dispatch("setRequirementStatus", true);
            // save eis info
            if (res.data.result.eis) {
              if (res.data.result.eis.birthday) {
                res.data.result.eis.birthday = res.data.result.eis.birthday.substring(0, 10);
              }

              dispatch("setEis", res.data.result.eis);

              if (res.data.result.eis.dependents) {
                dispatch("setDependents", JSON.parse(res.data.result.eis.dependents));
              }

              if (res.data.result.eis.work_experience) {
                dispatch("setWorkExperience", JSON.parse(res.data.result.eis.work_experience));
              }

              if (res.data.result.eis.character_reference) {
                dispatch(
                  "setCharacterReference",
                  JSON.parse(res.data.result.eis.character_reference)
                );
              }
            }
            // check if eis has value
          } else {
            dispatch("setRequirementStatus", false); // cannot retrieve requirements fields
          }
        } else {
          dispatch("setRequirementStatus", false); // cannot retrieve requirements fields
        }
        dispatch("loadingFalse");
      },
      (error) => {
        if (error.response.status === 403) {
          dispatch("resetUserAuth");
          dispatch("resetUserInfo");
          dispatch("resetUserProfile");
          router.push({ name: "user-login-forbidden" });
        }
        dispatch("setRequirementStatus", false); // cannot retrieve requirements fields
        dispatch("loadingFalse");
      }
    );
  },

  // dispatch to frontend
  getRequirements({ dispatch }, payload) {
    // pass request and prepare to submit data
    return new Promise((resolve, reject) => {
      dispatch("callRequirementApi", payload).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
