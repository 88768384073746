export default function guestadmin({ next, store }) {
  // console.log(window.location.href);
  store.dispatch('checkRole').then(() => {
    // console.log('store.getters.adminRole', store.getters.adminRole);
    if (store.getters.adminRole === 'admin') {
      return next({
        name: 'admin-home',
      });
    }
    return next();
  });
}
