<template>
  <div class="new-emp row">
    <div class="container-left col-xs-12 col-md-9">
      <AdminHeader></AdminHeader>
      <div class="new-emp__body">
        <p class="mt-10 mb-5 ml-10 admin__title admin__title--blue">Create New Employee</p>
        <FormNewUser></FormNewUser>
      </div>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import FormNewUser from '../../components/Admin/FormNewUser.vue';
import AdminHeader from '../../components/Admin/Header.vue';
import Sidebar from '../../components/Admin/Sidebar.vue';

export default {
  name: 'FormNew',
  components: {
    FormNewUser,
    AdminHeader,
    Sidebar,
  },
};
</script>
