import applocalstorage from '../../utils/applocalstorage';

const data = {
  userAuth: {
    access_token: '',
    refresh_token: '',
  },
  authState: {
    isRefreshing: false,
    refreshingCall: null,
  },
  userInfo: {
    user_id: '',
    user_role: '',
  },
};

const localStorageService = applocalstorage.getService();

export const getters = {
  userAuth: (state) => state.userAuth,
  authState: (state) => state.authState,
  userInfo: (state) => state.userInfo,
};

export const mutations = {
  setUserAuth: (state, payload) => {
    state.userAuth.access_token = payload.access_token;
    state.userAuth.refresh_token = payload.refresh_token;
    localStorageService.setToken(payload);
  },
  resetUserAuth: (state) => {
    state.userAuth.access_token = '';
    state.userAuth.refresh_token = '';
    localStorageService.clearToken();
  },
  setUserInfo: (state, payload) => {
    state.userInfo.user_id = payload.user_id;
    state.userInfo.user_role = payload.user_role;
    localStorageService.setUserInfo(payload);
    localStorageService.resetAdminRole();
  },
  resetUserInfo: (state) => {
    state.userInfo.user_id = '';
    state.userInfo.user_role = '';
    localStorageService.clearUserInfo();
  },
  setRefreshingState: (state, payload) => { state.authState.isRefreshing = payload; },
  setRefreshingCall: (state, payload) => { state.authState.refreshingCall = payload; },
};

export const actions = {
  setUserAuth({ commit }, payload) {
    // console.log(payload);
    commit('setUserAuth', payload);
  },
  resetUserAuth({ commit }) {
    commit('resetUserAuth');
  },
  setUserInfo({ commit }, payload) {
    commit('setUserInfo', payload);
  },
  resetUserInfo({ commit }) {
    commit('resetUserInfo');
  },
  setRefreshingState({ commit }, payload) {
    commit('setRefreshingState', payload);
  },
  setRefreshingCall({ commit }, payload) {
    commit('setRefreshingCall', payload);
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
