<template>
  <div class="require">
    <p class="admin__title">Onboarding Checklist</p>

    <div class="form__row" v-if="requirementstatus">
      <v-form ref="reqform">
        <div
          class="form__row--loop form__upload"
          v-for="item in requirements"
          :key="item.user_req_id"
        >
          <div class="form__attach form__moreinfo--mb row">
            <div class="col-sm-6 d-flex flex-column justify-space-between">
              <p class="form__input--label">
                {{ item.requirement }}
                <span v-if="haveStatus(item.file_key, item.have_status)" class="form__havestatus">
                  - {{ item.have_status === 1 ? "Accepted" : "" }}</span
                >
                <span
                  v-if="item.file_key === 'EIS'"
                  class="form__vieweis"
                  @click="gotoEmployeeEIS(item.user_id)"
                >
                  - VIEW</span
                >
              </p>
              <div class="form__attach-field" v-if="hasFileUpload(item.file_key)">
                <div v-if="item.attachment">
                  <button class="btn__link" type="button" @click="views3file(item.user_req_id)">
                    {{ item.attachment }}
                  </button>
                </div>
                <div v-if="!item.attachment">
                  <v-file-input
                    @change="fileChanged(item.file_key, item.user_req_id)"
                    class="form-control form__input"
                    :ref="`${item.file_key}_file_${item.user_req_id}`"
                    placeholder="upload file"
                    prepend-icon=""
                    :rules="fileRules"
                    show-size
                    :accept="formrules.acceptfiles"
                  ></v-file-input>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="d-flex flex-column justify-space-between">
                <div class="form__checkbox">
                  <v-checkbox
                    :ref="`${item.file_key}_okay_${item.user_req_id}`"
                    @change="checkboxChanged(item.file_key, item.user_req_id, item, 'okay')"
                    label="Okay"
                    :input-value="setOkay(item.status)"
                  ></v-checkbox>
                  <v-checkbox
                    :ref="`${item.file_key}_reject_${item.user_req_id}`"
                    @change="checkboxChanged(item.file_key, item.user_req_id, item, 'reject')"
                    label="Reject"
                    :input-value="setReject(item.status)"
                  ></v-checkbox>
                </div>
                <div class="form__reject" v-if="item.status == 2">
                  <v-text-field
                    outlined
                    required
                    :value="item.comments"
                    class="form__reject--field"
                    :ref="`${item.file_key}_rejecttxt_${item.user_req_id}`"
                    @keyup="rejecttxtChanged(item.file_key, item.user_req_id)"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__remarks-div row">
          <div class="col-sm-3">
            <p class="ma-0 form__remarks-label">Other Remarks</p>
          </div>
          <div class="col-sm-9">
            <v-textarea
              v-model="remarks"
              class="form__remarks"
              required
              outlined
              no-resize
              rows="4"
              @change="remarkChanged()"
            ></v-textarea>
          </div>
        </div>
        <div class="form__btn-div">
          <!-- <a :href="links.download_all" class="btn__blue btn__line" target="blank">Download all files</a> -->
          <button
            type="button"
            class="form__btn btn__blue"
            v-bind:disabled="!formReqValid ? true : false"
            @click="saveData"
          >
            Save Changes
          </button>
        </div>
      </v-form>
    </div>
    <div class="message__failed" v-if="!requirementstatus">
      <p class="message__failed--title">Something Went Wrong.</p>
      <a :href="currentpath" class="pt-6 btn__blue">Reload page</a>
    </div>
    <AdminDialog :modal="modal" :modalstatus="modalstatus"></AdminDialog>
    <v-dialog v-model="retryupdate.status" persistent no-click-animation>
      <button class="modal__btn--close" @click="goto(retryupdate.close, retryupdate.params)">
        <i class="material-icons">clear</i>
      </button>
      <div
        class="modal__body tk-museo-sans-rounded"
        :class="{ modal__failed: retryupdate.success === false }"
      >
        <p class="modal__title">{{ retryupdate.title }}</p>
        <p class="modal__msg">{{ retryupdate.msg }}</p>
        <ol class="modal__retry-list" v-if="retryupdate.items.length != 0">
          <li v-for="fail in retryupdate.items" :key="fail.user_req_id">{{ fail.requirement }}</li>
        </ol>

        <div class="modal__btn d-flex modal__btn--retry" v-if="retryupdate.link != 'close'">
          <button
            @click="goto(retryupdate.link, retryupdate.params)"
            class="form__btn btn__blue btn__line"
          >
            {{ retryupdate.button }}
          </button>
          <button @click="retryItems()" class="form__btn btn__blue">retry</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import AdminDialog from "./Dialog/Dialog.vue";
import applocalstorage from "../../utils/applocalstorage";

// Service for storing data to localstorage
const localStorageService = applocalstorage.getService();

export default {
  props: ["user"],
  name: "UserUpload",
  data() {
    return {
      currentpath: "",
      loading: true,
      formReqValid: false,
      attachments: {},
      failedAttachments: {},
      failedRequirements: [], // failed upload requirements
      // failedAttachments: [{
      //   refs: '',
      //   file: '',
      //   reqid: '',
      // }],
      remarks: "",
      modal: "",
      modalstatus: false,
      savebutton: true,
      successupdate: {
        status: true,
        title: "Success",
        msg: "You have updated the verified requirements.",
        button: "GO BACK",
        link: "admin-employee",
        params: this.$route.params.id.toString(),
        close: "admin-employee",
        success: true,
      },
      retryupdate: {
        status: false,
        title: "Oops!",
        msg: "Failed to upload the items below. Please try again.",
        items: {},
        button: "cancel",
        link: "admin-employee",
        params: this.$route.params.id.toString(),
        close: "admin-employee",
        success: false,
      },
      failedupdate: {
        status: true,
        title: "Oops!",
        msg: "Something went wrong. Please try again.",
        button: "GO BACK",
        link: "",
        params: "",
        close: "",
        success: false,
      },
      previousRequirements: "",
      s3count: 0,
      countloop: 0,
    };
  },
  mounted() {
    // get requirements
    this.currentpath = this.$router.currentRoute.path;
  },
  methods: {
    // view file from s3
    views3file(id) {
      const request = {
        user_req_id: id,
      };

      axios.post(this.viewfileapi, request).then(
        (res) => {
          if (Object.keys(res.data.result.presigned_url).length) {
            window.open(
              res.data.result.presigned_url,
              "_blank" // <- This is what makes it open in a new window.
            );
          } else {
            // fail to view file
          }
        },
        (err) => {
          // console.log(err);
        }
      );
    },
    getS3file(urls) {
      return new Promise((resolve, reject) => {
        const presigned = urls.presigned_url;
        // console.log(presigned);
        axios.get(presigned).then(
          (response) => {
            // check status code
            if (response.status === 200) {
              resolve();
            } else {
              reject();
            }
          },
          (error) => {
            // console.log(error);
            resolve();
          }
        );
      });
    },

    hasAttachment(refkey) {
      // console.log(this.$refs);
      const fileval = this.$refs[refkey][0].internalValue;
      if (fileval) {
        return true;
      }
      return false;
    },
    gotoEmployeeEIS(empId) {
      this.$router.push({
        name: "admin-employee-eis",
        params: { id: empId },
      });
    },
    // update requirements object on file changed
    fileChanged(key, reqid) {
      const refkey = `${key}_file_${reqid}`;

      // check if requiremt form is valid
      this.formReqValid = this.$refs.reqform.validate();

      // check if file exist
      if (this.$refs[refkey][0].internalValue) {
        const fileval = this.$refs[refkey][0].internalValue;

        // check filesize and file format is valid
        if (this.formReqValid) {
          // update requirement objects
          Object.keys(this.requirements).forEach((val, i) => {
            if (this.requirements[i].user_req_id === reqid) {
              this.requirements[i].attachment = this.renameFile(key, reqid, fileval);
              this.requirements[i].content_type = fileval.type;
              // save to attachment object
              this.saveAttachments(reqid, fileval);
            }
          });
        }
      }
    },
    setHaveStatus(reqid, status) {
      Object.keys(this.requirements).forEach((val, i) => {
        if (this.requirements[i].user_req_id === reqid) {
          this.requirements[i].have_status = status;
        }
      });
    },

    // save to attachment objects
    saveAttachments(reqid, file) {
      const userreqid = reqid.toString();
      this.attachments[userreqid] = file;

      // if reqid exist in failed array, remove
      delete this.failedAttachments[userreqid];
    },

    // save to failed attachments
    saveFailedAttachments(reqid, file) {
      const userreqid = reqid.toString();
      this.failedAttachments[userreqid] = file;
    },

    savefailedRequirements(reqOb) {
      this.failedRequirements.push(reqOb);
    },

    // rename filename based on format
    renameFile(key, reqid, file) {
      let filename = "";
      const firstname = this.user.first_name.replace(/[^a-zA-Z]/g, "_");
      const lastname = this.user.last_name.replace(/[^a-zA-Z]/g, "_");

      if (file) {
        const fileext = file.name.split(".").pop();
        filename = `${key}-${firstname}${lastname}-${reqid}.${fileext}`;
      }
      return filename;
    },

    // format requirement key before submit
    getUpdatedFields(req, prev) {
      const arrReq = [];

      const require = req; // latest
      const previous = prev; // previous

      Object.keys(req).forEach((val, i) => {
        const newObj = {};

        if (require[i].status !== previous[i].status) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["requirement"] = require[i].requirement;
          newObj["status"] = require[i].status;
        }

        if (require[i].comments !== previous[i].comments && require[i].comments) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["requirement"] = require[i].requirement;
          newObj["comments"] = require[i].comments;
        }

        // check if id is in attachments
        const hasAttach = this.getAttachmentsById(require[i].user_req_id);

        if (hasAttach) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["user_id"] = require[i].user_id;
          newObj["requirement"] = require[i].requirement;
          newObj["attachment"] = require[i].attachment;
          newObj["content_type"] = require[i].content_type;
        }

        if (Object.keys(newObj).length !== 0) {
          arrReq.push(newObj);
        }
      });

      return arrReq;
    },

    getFailedItems(req) {
      const arrReq = [];

      const require = req; // latest

      Object.keys(require).forEach((val, i) => {
        const newObj = {};

        const hasAttach = this.getAttachmentsById(require[i].user_req_id);

        if (hasAttach) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["user_id"] = require[i].user_id;
          newObj["requirement"] = require[i].requirement;
          newObj["attachment"] = require[i].attachment;
          newObj["content_type"] = require[i].content_type;
        }

        delete require[i].photo_url;
        delete require[i].description;
        if (Object.keys(newObj).length !== 0) {
          arrReq.push(newObj);
        }
      });

      return arrReq;
    },

    // save requirements
    saveData() {
      // start loading

      // check if valid
      this.$store.dispatch("loadingTrue");

      // get previous data
      const previous = JSON.parse(localStorage.getItem("previous"));

      // get admin role
      const arole = localStorageService.getAdminRole();

      // process request
      const request = {
        requirements: this.getUpdatedFields(this.requirements, previous),
        remarks: this.remarks,
        user_id: this.req_user_id,
        role: arole,
      };

      // TODO create dispatch
      this.submitRequirements(request);
    },

    submitRequirements(request) {
      axios.post(this.useruploadapi, request).then(
        (res) => {
          if (res.data.success) {
            // check presigned url array
            if (Object.keys(res.data.result.presigned_urls).length) {
              this.uploadToS3(res.data.result).then(
                (success) => {
                  // if number of success match attachments uploaded
                  if (this.s3count === Object.keys(this.attachments).length) {
                    this.$store.dispatch("setModal", this.successupdate);
                  } else {
                    // TODO show error files
                    this.showRetryItems(this.failedRequirements);
                  }
                },
                (failed) => {
                  this.$store.dispatch("setModal", this.failedupdate);
                  // something went wrong
                }
              );
            } else {
              // success update comments
              this.$store.dispatch("setModal", this.successupdate);
            }
          } else {
            this.$store.dispatch("setModal", this.failedupdate);
          }
        },
        (error) => {
          // console.log(error);
          if (error.response.status === 403) {
            this.$store.dispatch("resetUserAuth");
            this.$store.dispatch("resetUserInfo");
            this.$store.dispatch("resetUserProfile");
            this.$router.push({ name: "admin-login-forbidden" });
          }
          this.$store.dispatch("setModal", this.failedupdate);
        }
      );
    },

    // get attachments by user requirement id
    getAttachmentsById(id) {
      return this.attachments[id];
    },

    // get requirement by user requirement id
    getRequirementById(id) {
      let req;

      for (let i = 0; i < Object.keys(this.requirements).length; i += 1) {
        if (this.requirements[i].user_req_id === Number(id)) {
          req = this.requirements[i];
        }
      }
      return req;
    },

    // show failed items
    showRetryItems(failedreq) {
      this.$store.dispatch("resetModal");
      // show failed upload dialog
      this.retryupdate.status = true;
      this.retryupdate.items = failedreq;
    },

    // TODO retry failed items
    retryItems() {
      this.retryupdate.status = false; // hide retry modal
      this.retryupdate.items = {}; // reset failed items
      this.$store.dispatch("loadingTrue");
      // save new attachment array based on failed items
      this.attachments = this.failedAttachments;
      const request = {
        requirements: this.getFailedItems(this.failedRequirements),
        user_id: this.req_user_id,
      };

      // console.log('retry');
      // console.log(request);
      // console.log(this.attachments);
      // console.log('========================');

      this.submitRequirements(request);
    },

    // upload file to s3 using presigned url
    uploadToS3(urls) {
      return new Promise((resolve) => {
        const presigned = urls.presigned_urls;
        const nodes = Object.keys(presigned);
        // TODO reset failed items
        this.failedAttachments = {};
        this.failedRequirements = [];
        this.s3count = 0;
        this.countloop = 0;
        for (let i = 0; i < nodes.length; i += 1) {
          const fileattach = this.getAttachmentsById(nodes[i]);
          if (fileattach) {
            const url = presigned[nodes[i]];
            // console.log(url);

            axios.put(url, fileattach).then(
              (response) => {
                // check status code
                if (response.status === 200) {
                  // console.log(response.status);
                  // count success
                  this.s3count += 1;
                  this.countloop += 1;
                  if (this.countloop === Object.keys(this.attachments).length) {
                    // console.log('resolve');
                    resolve();
                  }
                } else {
                  // console.log('failed upload');
                  this.countloop += 1;
                  this.saveFailedAttachments(nodes[i], fileattach);
                  this.savefailedRequirements(this.getRequirementById(nodes[i]));
                  if (this.countloop === Object.keys(this.attachments).length) {
                    // console.log('resolve');
                    resolve();
                  }
                }
                return this.s3count;
                // count failed and save to retry array
              },
              (error) => {
                // console.log(error);
                // console.log('failed upload');
                this.countloop += 1;
                this.saveFailedAttachments(nodes[i], fileattach);
                this.savefailedRequirements(this.getRequirementById(nodes[i]));
                if (this.countloop === Object.keys(this.attachments).length) {
                  // console.log('resolve');
                  resolve();
                }
              }
            );
          }
        }
      });
    },

    goto(des, params = "") {
      this.$store.dispatch("resetModal");
      if (des === this.$router.currentRoute.name) {
        window.location.href = this.$router.currentRoute.fullPath;
      } else if (des) {
        // if link name has destination
        if (params) {
          this.$router.push({
            name: des,
            params: { id: params },
          });
        } else {
          this.$router.push({
            name: des,
          });
        }
      }
    },

    // show success dialog
    showSuccessDialog() {
      this.modal = this.successupdate;
      this.modalstatus = true;
    },

    // check if requirement field has file upload
    hasFileUpload(filekey) {
      if (filekey) {
        const checknofile = this.nofileupload.includes(filekey);
        const checkreadarr = this.readhavestatus.includes(filekey);

        // if field has file upload
        return !checknofile && !checkreadarr;
      }

      return false;
    },

    haveStatus(filekey, status) {
      if (filekey) {
        const checkreadarr = this.readhavestatus.includes(filekey);
        // show have status
        return checkreadarr && status;
      }

      return false;
    },

    remarkChanged() {
      // check if requiremt form is valid
      this.formReqValid = this.$refs.reqform.validate();
    },

    // watch reject remarks
    rejecttxtChanged(key, reqid) {
      const refkey = `${key}_rejecttxt_${reqid}`;
      // check if file exist
      const inputval = this.$refs[refkey][0].internalValue;

      // check if requiremt form is valid
      this.formReqValid = this.$refs.reqform.validate();

      // update requirement objects
      Object.keys(this.requirements).forEach((val, i) => {
        if (this.requirements[i].user_req_id === reqid) {
          this.requirements[i].comments = inputval;
        }
      });
    },

    // watch checkbox status
    checkboxChanged(key, reqid, item, str) {
      const refkey = `${key}_${str}_${reqid}`;
      const refcommkey = `${key}_rejecttxt_${item.user_req_id}`;
      // this.formReqValid = this.$refs.reqform.validate();
      // console.log(this.$refs[refcommkey]);

      Object.keys(this.requirements).forEach((val, i) => {
        // get object being updated
        if (this.requirements[i].user_req_id === reqid) {
          // check what is being updated
          this.requirements[i].status = this.setStatus(this.$refs[refkey][0].internalValue, str);
          this.requirements[i].comments = "";

          // TODO check if same as previous
          // clear remarks if status is okay
          if (this.requirements[i].status === 1 || this.requirements[i].status === 0) {
            // check if requiremt form is valid
            this.formReqValid = this.$refs.reqform.validate();
          } else {
            this.formReqValid = false;
          }
        }
      });
    },

    // set status based on checkbox
    setStatus(val, str) {
      let checkstatus = "";
      const checkval = val;

      if (checkval === true) {
        if (str === "okay") {
          checkstatus = 1; // set to okay
        } else if (str === "reject") {
          checkstatus = 2; // set to reject
        } else {
          checkstatus = 0;
        }
      } else {
        checkstatus = 0; // reset status
      }

      return checkstatus;
    },

    // set checkbox value
    setReject(status) {
      let setstatus = "";
      if (status === 2) {
        setstatus = true;
      }

      return setstatus;
    },

    // set checkbox value
    setOkay(status) {
      let setstatus = "";
      if (status === 1) {
        setstatus = true;
      }
      return setstatus;
    },
  },
  computed: {
    ...mapGetters([
      "links",
      "useruploadapi",
      "getrequirementsapi",
      "viewfileapi",
      "nofileupload",
      "readhavestatus",
      "formrules",
      "requirements",
      "requirementstatus",
      "req_user_id",
    ]),
    fileRules() {
      return [
        // TODO show failed upload files
        (value) =>
          !value || value.size < this.formrules.filesize || "File should be less than 5 MB.",
        (value) => {
          if (!value) {
            return true;
          }

          const filex = `.${value.name.split(".").pop()}`;

          const filetype = value.type;

          if (!this.formrules.acceptfiles.includes(filex) && !filetype.includes("image")) {
            return "Invalid File Type";
          }
          return true;
        },
      ];
    },
  },
  components: {
    AdminDialog,
  },
};
</script>
