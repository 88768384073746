import Vue from 'vue';
import GoogleLogin from 'vue-google-login';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import vuetify from './plugins/vuetify';
import interceptorsSetup from './utils/interceptors';

// set the interceptors
interceptorsSetup();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  GoogleLogin,
  render: (h) => h(App),
}).$mount('#app');
