<template>
  <div class="user-req">
    <div v-if="requirementstatus" class="row">
      <div
        class="user-req--header col-xs-12 col-sm-12 d-flex align-center justify-space-between py-1 px-4"
      >
        <p class="user-req--title ma-0">Onboarding Checklist</p>
        <router-link :to="{ name: 'user-home' }" class="user-req--link">
          <i class="material-icons">arrow_back</i> Back to Dashboard</router-link
        >
      </div>
      <div class="container-right col-xs-12 col-md-3 user-req-list">
        <UserBoardListBar></UserBoardListBar>
      </div>
      <div class="container-left col-xs-12 col-md-9 user-req--right">
        <div class="user-req__eis">
          <p class="admin__title admin__title--blue">Employee Information Sheet</p>
          <EIS :eisval="eis"></EIS>
        </div>
        <UserFileRequirement :user="userprofile"></UserFileRequirement>
      </div>
    </div>
    <div class="message__failed" v-if="!requirementstatus">
      <p class="message__failed--title">Something Went Wrong.</p>
      <a :href="currentpath" class="pt-6 btn__blue">Reload page</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios';
import { mapGetters } from "vuex";
import UserBoardListBar from "../../components/User/UserBoardListBar.vue";
import UserFileRequirement from "../../components/User/UserFileRequirement.vue";
import applocalstorage from "../../utils/applocalstorage";

// EIS
import EIS from "../../components/User/EIS/EIS.vue";

// Service for storing data to localstorage
const localStorageService = applocalstorage.getService();

export default {
  name: "UserRequirements",
  // props: ['user'],
  data() {
    return {
      currentpath: ""
    };
  },
  components: {
    UserBoardListBar,
    UserFileRequirement,
    EIS
  },
  computed: {
    ...mapGetters(["getrequirementsapi", "requirementstatus", "eis", "userprofile", "dependents"])
  },
  mounted() {
    this.getRequirements();
    this.currentpath = this.$router.currentRoute.path;
  },
  methods: {
    getRequirements() {
      this.$store.dispatch("loadingTrue");
      // get user id of logged in user
      if (localStorageService.getUserId()) {
        const request = {
          user_id: localStorageService.getUserId() // this.user.user_id, // this.$route.params.id.toString(),
        };
        // check if user profile exist
        if (Object.keys(this.userprofile).length === 0) {
          this.$store.dispatch("getUserProfile", request).then(() => {
            this.$store.dispatch("loadingFalse");
          });
        }
        this.$store.dispatch("getRequirements", request).then(() => {
          // set dependents
          if (this.eis.dependents) {
            this.$store.dispatch("setDependents", JSON.parse(this.eis.dependents.slice(1, -1)));
          }

          // set workplace
          if (this.eis.work_experience) {
            this.$store.dispatch(
              "setWorkExperience",
              JSON.parse(this.eis.work_experience.slice(1, -1))
            );
          }

          // set char ref
          if (this.eis.character_reference) {
            this.$store.dispatch(
              "setCharacterReference",
              JSON.parse(this.eis.character_reference.slice(1, -1))
            );
          }

          this.$store.dispatch("loadingFalse");
        });
      } else {
        this.$store.dispatch("loadingFalse");
        this.$router.push({
          name: "user-login"
        });
      }
    }
  }
};
</script>
