<template>
  <div class="user-require form__upload form__requirements">
    <div class="form__row">
      <v-form ref="reqform">
        <div
          class="form__row--loop form__upload"
          v-for="item in requirements"
          :key="item.user_req_id"
          :id="generateId(item.file_key, item.user_req_id)"
        >
          <div class="form__attach form__moreinfo--mb row" v-if="hideReq(item.file_key)">
            <div class="col-sm-7 d-flex flex-column justify-center">
              <p class="require__title">{{ item.requirement }}</p>
              <p class="ma-0 form__input--label require__desc" v-html="item.description"></p>
              <div class="form__attach-field" v-if="hasFileUpload(item.file_key)">
                <div v-if="item.attachment && item.status != 2">
                  <button type="button" @click="views3file(item.user_req_id)" class="btn__link">
                    {{ item.attachment }}
                  </button>
                </div>
                <div>
                  <v-file-input
                    @change="fileChanged(item.file_key, item.user_req_id)"
                    class="form-control form__input"
                    :ref="`${item.file_key}_file_${item.user_req_id}`"
                    placeholder="upload file"
                    prepend-icon=""
                    :rules="fileRules"
                    show-size
                    :accept="formrules.acceptfiles"
                  ></v-file-input>
                </div>
              </div>
              <div class="form__accept" v-if="!hasFileUpload(item.file_key)">
                <div v-if="item.file_key == 'ACCEPT'" class="form__accept--field">
                  <div class="form__accept--desc">
                    <p>
                      Click on the
                      <a :href="links.accept_user" target="_blank" class="form__link">link</a> to
                      review the terms.
                    </p>
                  </div>
                  <div class="d-flex form__accept--checkbox">
                    <v-checkbox
                      :ref="`${item.file_key}_accept_${item.user_req_id}`"
                      @change="haveAccept(item.file_key, item.user_req_id, item, 'accept')"
                      :input-value="setOkay(item.have_status)"
                    ></v-checkbox>
                    <p>
                      I have read and understand the
                      <a :href="links.accept_user" target="_blank" class="form__link">
                        Acceptance Use of Policy for Company Issued Devices.</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-5 d-flex justify-center align-center" v-if="item.photo_url">
              <img
                :src="`/img/sample/${item.photo_url}`"
                alt="requirement image"
                class="require__img"
              />
            </div>
          </div>
        </div>
        <div class="form__btn-div">
          <button
            type="button"
            class="form__btn btn__blue"
            v-bind:disabled="!formReqValid && !eisfieldstatus ? true : false"
            @click="openPrivacyDialog"
          >
            Save
          </button>
        </div>
      </v-form>
    </div>

    <UserDialog :modal="modal" :modalstatus="modalstatus"></UserDialog>
    <v-dialog v-model="retryupdate.status" persistent no-click-animation>
      <button class="modal__btn--close" @click="goto(retryupdate.close, retryupdate.params)">
        <i class="material-icons">clear</i>
      </button>
      <div
        class="modal__body tk-museo-sans-rounded"
        :class="{ modal__failed: retryupdate.success === false }"
      >
        <p class="modal__title">{{ retryupdate.title }}</p>
        <p class="modal__msg">{{ retryupdate.msg }}</p>
        <ol class="modal__retry-list" v-if="retryupdate.items.length != 0">
          <li v-for="fail in retryupdate.items" :key="fail.user_req_id">{{ fail.requirement }}</li>
        </ol>

        <div class="modal__btn d-flex modal__btn--retry" v-if="retryupdate.link != 'close'">
          <button
            @click="goto(retryupdate.link, retryupdate.params)"
            class="form__btn btn__blue btn__line"
          >
            {{ retryupdate.button }}
          </button>
          <button @click="retryItems()" class="form__btn btn__blue">retry</button>
        </div>
      </div>
    </v-dialog>

    <!-- Privacy Policy Modal -->
    <v-dialog v-model="privacy.status" persistent no-click-animation>
      <div class="modal__body modal__privacy tk-museo-sans-rounded">
        <p class="modal__title">{{ privacy.title }}</p>
        <div class="modal__section">
          <ol class="modal__letter-list" type="A">
            <li v-for="letter in privacy.letters" :key="letter">
              <p>
                <strong>{{ letter.header }}</strong>
              </p>
              <ol class="modal__number-list" v-if="letter.numbers">
                <li v-for="number in letter.numbers" :key="number">
                  <p>{{ number.subHeader }}</p>
                  <ul class="modal__bullet-list mb-5" v-if="number.details">
                    <li v-for="detail in number.details" :key="detail">
                      <span>
                        <strong>{{ detail.title }}</strong> –
                        {{ detail.details }}
                      </span>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div class="modal__btn">
          <button @click="closePrivacyDialog" class="btn__white">Decline</button>
          <button @click="saveData" class="btn__blue">Accept</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import UserDialog from "./Dialog/Dialog.vue";
import applocalstorage from "../../utils/applocalstorage";

// Service for storing data to localstorage
const localStorageService = applocalstorage.getService();

export default {
  props: ["user"],
  name: "UserUpload",
  data() {
    return {
      loading: true,
      formReqValid: true,
      attachments: {},
      failedAttachments: {},
      failedRequirements: [],
      remarks: "",
      modal: "",
      modalstatus: false,
      savebutton: true,
      successupdate: {
        status: true,
        title: "Thank You",
        msg: "We have received your requirements. Our HR representative will verify and provide feedback on the items you have submitted.",
        button: "GO TO DASHBOARD",
        link: "user-home",
        params: "",
        close: "user-home",
        success: true,
      },
      retryupdate: {
        status: false,
        title: "Oops!",
        msg: "Failed to upload the items below. Please try again.",
        items: {},
        button: "cancel",
        link: "user-requirements",
        params: "",
        close: "user-requirements",
        success: false,
      },
      failedupdate: {
        status: true,
        title: "Oops!",
        msg: "Something went wrong. Please try again.",
        button: "GO BACK",
        link: "",
        params: "",
        close: "",
        success: false,
      },
      privacy: {
        status: false,
        title: "To proceed, please read and accept the Privacy Notice",
        letters: [
          {
            header: "By giving my confirmation below, I signify, understand that:",
            numbers: [
              {
                subHeader:
                  "917Ventures will collect and process my information for the following purposes:",
                details: [
                  {
                    title: "Basic Identifiers and Contact Information",
                    details:
                      "To verify my identity; to maintain proper communications with me throughout my employment; and to identify potential conflicts of interest arising from my employment with 917Ventures.",
                  },
                  {
                    title: "Financial Records",
                    details:
                      "To process and manage my compensation and benefits, including loan and insurance coverage entitlements.",
                  },
                  {
                    title: "Administrative Records",
                    details:
                      "Records on disciplinary matters, grievances, queries and complaints, absences, and termination of employment or engagement (including provision of references) during my employment.",
                  },
                  {
                    title: "Other Technical Information",
                    details:
                      "To monitor my compliance with 917Ventures’ Acceptable Use Policy among other policies and to optimize network and system performance.",
                  },
                  {
                    title: "917Ventures-Generated Information",
                    details:
                      "Identification information, access credentials, performance metrics, learning and developmental progress, and closed-circuit television footage at the workplace, and work assignment records as part of 917Ventures’ workforce management and development; to perform analysis on my Employee Data for 917Ventures’ internal consumption and reporting.",
                  },
                ],
              },
              {
                subHeader:
                  "917Ventures may perform analysis on my Employee Data for 917Ventures’ internal consumption and reporting.",
              },
              {
                subHeader:
                  "917Ventures may share my Basic Identifiers and Contact Information as well as 917Ventures-Generated Information with the 917Ventures Group Member Companies for the purpose of enabling contact and collaboration with them subject to proper documentation.",
              },
              {
                subHeader:
                  "917Ventures may outsource or contract the processing of my data to external parties, even without my further consent, to fulfill any of the purposes described above including the verification of the existence, truthfulness, and/or accuracy of the information I will provide and in compliance with government requirements.",
              },
              {
                subHeader:
                  "917Ventures will protect and retain my information in accordance with the Privacy Policy of 917Ventures and uphold my rights as a data subject.",
              },
            ],
          },
          {
            header: "I specifically provide my consent for 917Ventures to collect and process:",
            numbers: [
              {
                subHeader:
                  "Sensitive personal identifiable information to further verify my identity;",
              },
              {
                subHeader:
                  "Information regarding my educational and employment background included in my curriculum vitae and/or resume for record-keeping purposes;",
              },
              {
                subHeader:
                  "Government-issued identification to comply with existing employment-related laws and regulations;",
              },
              {
                subHeader:
                  "Administrative, civil, and/or criminal cases and other government clearances to determine if my employment may compromise 917Ventures’ ethical standards; and",
              },
              {
                subHeader:
                  "Information that I may have disclosed of relevant individuals and/or external parties, who I have notified and obtained the consent of, in relation to my employment.",
              },
            ],
          },
          {
            header:
              "I understand that I may withhold my consent or later on withdraw it at any time. Without such consent, however, 917Ventures will not be able to continue with my employment.",
          },
        ],
      },
      s3count: 0,
      countloop: 0,
    };
  },
  methods: {
    closePrivacyDialog() {
      this.privacy.status = false;
    },
    openPrivacyDialog() {
      this.privacy.status = true;
    },
    views3file(id) {
      const request = {
        user_req_id: id,
      };
      // console.log(request);

      axios.post(this.viewfileapi, request).then(
        (res) => {
          // console.log(res);
          if (Object.keys(res.data.result.presigned_url).length) {
            window.open(
              res.data.result.presigned_url,
              "_blank" // <- This is what makes it open in a new window.
            );
          } else {
            // fail to view file
          }
        },
        (err) => {
          // console.log(err);
        }
      );
    },
    getS3file(urls) {
      return new Promise((resolve, reject) => {
        const presigned = urls.presigned_url;
        // console.log(presigned);
        axios.get(presigned).then(
          (response) => {
            // check status code
            if (response.status === 200) {
              resolve();
            } else {
              reject();
            }
          },
          (error) => {
            // console.log(error);
            resolve();
          }
        );
      });
    },

    generateId(key, id) {
      let hash = "";
      const hide = this.hideReq(key);

      if (hide) {
        hash = `id_${key}${id}`;
      }

      return hash;
    },
    // update requirements object on file changed
    fileChanged(key, reqid) {
      const refkey = `${key}_file_${reqid}`;

      // check if requiremt form is valid
      this.formReqValid = this.$refs.reqform.validate();
      // console.log(this.formReqValid);
      const previous = JSON.parse(localStorage.getItem("previous"));

      // reset have status
      this.setHaveStatus(reqid, 0);

      // check if file exist
      if (this.$refs[refkey][0].internalValue) {
        const fileval = this.$refs[refkey][0].internalValue;

        // check filesize and file format is valid
        if (this.formReqValid) {
          // update requirement objects
          Object.keys(this.requirements).forEach((val, i) => {
            if (this.requirements[i].user_req_id === reqid) {
              this.requirements[i].attachment = this.renameFile(key, reqid, fileval);
              this.requirements[i].content_type = fileval.type;
              this.requirements[i]["uploaded"] = true;
              // console.log(previous[i].have_status);
              this.requirements[i].have_status = 1;
              // save to attachment object
              this.saveAttachments(reqid, fileval);
            }
          });
        }
      } else {
        Object.keys(this.requirements).forEach((val, i) => {
          if (this.requirements[i].user_req_id === reqid) {
            this.requirements[i]["uploaded"] = false;
          }
        });
      }
    },

    setHaveStatus(reqid, status) {
      Object.keys(this.requirements).forEach((val, i) => {
        if (this.requirements[i].user_req_id === reqid) {
          this.requirements[i].have_status = status;
        }
      });
    },

    // save to attachment objects
    saveAttachments(reqid, file) {
      const userreqid = reqid.toString();
      this.attachments[userreqid] = file;

      // if reqid exist in failed array, remove
      delete this.failedAttachments[userreqid];
    },

    saveFailedAttachments(reqid, file) {
      const userreqid = reqid.toString();
      this.failedAttachments[userreqid] = file;
    },

    savefailedRequirements(reqOb) {
      this.failedRequirements.push(reqOb);
    },
    // rename filename based on format
    renameFile(key, reqid, file) {
      let filename = "";
      const firstname = this.user.first_name.replace(/[^a-zA-Z]/g, "_");
      const lastname = this.user.last_name.replace(/[^a-zA-Z]/g, "_");

      if (file) {
        const fileext = file.name.split(".").pop();
        filename = `${key}-${firstname}${lastname}-${reqid}.${fileext}`;
      }
      return filename;
    },

    // format requirement key before submit
    getUpdatedFields(req, prev) {
      const arrReq = [];

      const require = req; // latest
      const previous = prev; // previous

      Object.keys(req).forEach((val, i) => {
        const newObj = {};

        // for EIS, accept user
        // if (require[i].have_status !== previous[i].have_status) {
        if (require[i].file_key === "EIS") {
          const statuseis = previous[i].status === 1 ? 1 : 0;
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["requirement"] = require[i].requirement;
          newObj["have_status"] = 1; // submitted
          newObj["status"] = statuseis; // reset to 0 since admin need to review new submitted
        }

        if (require[i].file_key === "ACCEPT") {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["requirement"] = require[i].requirement;
          newObj["have_status"] = require[i].have_status;
        }
        // }

        const hasAttach = this.getAttachmentsById(require[i].user_req_id);

        if (hasAttach) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["user_id"] = require[i].user_id;
          newObj["requirement"] = require[i].requirement;
          newObj["attachment"] = require[i].attachment;
          newObj["content_type"] = require[i].content_type;
        }

        if (Object.keys(newObj).length !== 0) {
          arrReq.push(newObj);
        }
      });
      return arrReq;
    },

    getFailedItems(req) {
      const arrReq = [];

      const require = req; // latest

      Object.keys(require).forEach((val, i) => {
        const newObj = {};

        const hasAttach = this.getAttachmentsById(require[i].user_req_id);

        if (hasAttach) {
          newObj["user_req_id"] = require[i].user_req_id;
          newObj["user_id"] = require[i].user_id;
          newObj["requirement"] = require[i].requirement;
          newObj["attachment"] = require[i].attachment;
          newObj["content_type"] = require[i].content_type;
        }

        if (Object.keys(newObj).length !== 0) {
          arrReq.push(newObj);
        }
      });
      return arrReq;
    },

    // save requirements
    saveData() {
      // start loading
      this.closePrivacyDialog();
      this.$store.dispatch("loadingTrue");
      this.eis.dependents = JSON.stringify(this.dependents);
      this.eis.work_experience = JSON.stringify(this.work_experience);
      this.eis.character_reference = JSON.stringify(this.character_reference);

      // check if valid
      const previous = JSON.parse(localStorage.getItem("previous"));

      // get user role
      const urole = localStorageService.getUserRole();

      // eis payload
      const eisPayload = {
        user_id: this.eis.user_id,
        last_name: this.eis.last_name,
        first_name: this.eis.first_name,
        nickname: this.eis.nickname,
        birthday: this.eis.birthday,
        gender: this.eis.gender,
        age: this.eis.age,
        civil_status: this.eis.civil_status,
        country_of_birth: this.eis.country_of_birth,
        mailing_address: this.eis.mailing_address,
        tin: this.eis.tin,
        sss: this.eis.sss,
        pagibig: this.eis.pagibig,
        philhealth: this.eis.philhealth,
        emergency_contact_name: this.eis.emergency_contact_name,
        emergency_contact_relationship: this.eis.emergency_contact_relationship,
        emergency_contact_number: this.eis.emergency_contact_number,
        emergency_contact_address: this.eis.emergency_contact_address,
        dependents: this.eis.dependents,
        work_experience: this.eis.work_experience,
        character_reference: this.eis.character_reference,
      };

      if (this.eis.middle_name.trim() !== "") {
        eisPayload.middle_name = this.eis.middle_name;
      }

      if (this.eis.suffix) {
        eisPayload.suffix = this.eis.suffix;
      }

      // process request
      const request = {
        requirements: this.getUpdatedFields(this.requirements, previous),
        eis: eisPayload,
        user_id: this.req_user_id,
        role: urole,
      };

      // TODO create dispatch
      this.submitRequirements(request);
    },

    submitRequirements(request) {
      axios.post(this.useruploadapi, request).then(
        (res) => {
          if (res.data.success) {
            // check presigned url array
            if (Object.keys(res.data.result.presigned_urls).length) {
              this.uploadToS3(res.data.result).then(
                (success) => {
                  // if number of success match attachments uploaded
                  if (this.s3count === Object.keys(this.attachments).length) {
                    this.$store.dispatch("setModal", this.successupdate);
                  } else {
                    // TODO show error files
                    this.showRetryItems(this.failedRequirements);
                  }
                },
                (failed) => {
                  this.$store.dispatch("setModal", this.failedupdate);
                  // something went wrong
                }
              );
            } else {
              // success update comments
              this.$store.dispatch("setModal", this.successupdate);
            }
          } else {
            this.$store.dispatch("setModal", this.failedupdate);
          }
        },
        (error) => {
          if (error.response.status === 403) {
            this.$store.dispatch("resetUserAuth");
            this.$store.dispatch("resetUserInfo");
            this.$store.dispatch("resetUserProfile");
            this.$router.push({ name: "user-login-forbidden" });
          }
          this.$store.dispatch("setModal", this.failedupdate);
        }
      );
    },

    // get attachments by user requirement id
    getAttachmentsById(id) {
      return this.attachments[id];
    },

    // get requirement by user requirement id
    getRequirementById(id) {
      let req;

      for (let i = 0; i < Object.keys(this.requirements).length; i += 1) {
        if (this.requirements[i].user_req_id === Number(id)) {
          req = this.requirements[i];
        }
      }
      return req;
    },

    // show failed items
    showRetryItems(failedreq) {
      this.$store.dispatch("resetModal");
      // show failed upload dialog
      this.retryupdate.status = true;
      this.retryupdate.items = failedreq;
    },

    // TODO retry failed items
    retryItems() {
      this.retryupdate.status = false; // hide retry modal
      this.retryupdate.items = {}; // reset failed items
      this.$store.dispatch("loadingTrue");
      // save new attachment array based on failed items
      this.attachments = this.failedAttachments;
      const request = {
        requirements: this.getFailedItems(this.failedRequirements),
        user_id: this.req_user_id,
      };

      // console.log('retry');
      // console.log(request);
      // console.log(this.attachments);
      // console.log('========================');

      this.submitRequirements(request);
    },

    // upload file to s3 using presigned url
    uploadToS3(urls) {
      return new Promise((resolve) => {
        const presigned = urls.presigned_urls;
        const nodes = Object.keys(presigned);
        // TODO reset failed items
        this.failedAttachments = {};
        this.failedRequirements = [];
        this.s3count = 0;
        this.countloop = 0;
        for (let i = 0; i < nodes.length; i += 1) {
          const fileattach = this.getAttachmentsById(nodes[i]);
          if (fileattach) {
            const url = presigned[nodes[i]];

            const uninterceptedAxiosInstance = axios.create();
            uninterceptedAxiosInstance
              .put(url, fileattach, {
                headers: {
                  ContentType: fileattach.type,
                },
              })
              .then(
                (response) => {
                  // check status code
                  if (response.status === 200) {
                    // count success
                    this.s3count += 1;
                    this.countloop += 1;
                    if (this.countloop === Object.keys(this.attachments).length) {
                      resolve();
                    }
                  } else {
                    // console.log('failed upload');
                    this.countloop += 1;
                    this.saveFailedAttachments(nodes[i], fileattach);
                    this.savefailedRequirements(this.getRequirementById(nodes[i]));
                    if (this.countloop === Object.keys(this.attachments).length) {
                      // console.log('resolve');
                      resolve();
                    }
                  }
                  return this.s3count;
                  // count failed and save to retry array
                },
                (error) => {
                  // console.log('failed upload');
                  this.countloop += 1;
                  this.saveFailedAttachments(nodes[i], fileattach);
                  this.savefailedRequirements(this.getRequirementById(nodes[i]));
                  if (this.countloop === Object.keys(this.attachments).length) {
                    // console.log('resolve');
                    resolve();
                  }
                }
              );
          }
        }
      });
    },

    goto(des, params = "") {
      this.$store.dispatch("resetModal");
      if (des === this.$router.currentRoute.name) {
        window.location.href = this.$router.currentRoute.fullPath;
      } else if (des) {
        // if link name has destination
        if (params) {
          this.$router.push({
            name: des,
            params: { id: params },
          });
        } else {
          this.$router.push({
            name: des,
          });
        }
      }
    },

    // show success dialog
    showSuccessDialog() {
      this.modal = this.successupdate;
      this.modalstatus = true;
    },

    // check if requirement field has file upload
    hasFileUpload(filekey) {
      if (filekey) {
        const checknofile = this.nofileupload.includes(filekey);
        const checkreadarr = this.readhavestatus.includes(filekey);

        // if field has file upload
        return !checknofile && !checkreadarr;
      }

      return false;
    },

    hideReq(filekey) {
      if (filekey === "EIS") {
        return false;
      }
      return true;
    },

    haveStatus(filekey, status) {
      if (filekey) {
        const checkreadarr = this.readhavestatus.includes(filekey);
        // show have status
        return checkreadarr && status;
      }

      return false;
    },

    // watch reject remarks
    rejecttxtChanged(key, reqid) {
      const refkey = `${key}_rejecttxt_${reqid}`;
      // check if file exist
      const inputval = this.$refs[refkey][0].internalValue;

      // update requirement objects
      Object.keys(this.requirements).forEach((val, i) => {
        if (this.requirements[i].user_req_id === reqid) {
          this.requirements[i].comments = inputval;
        }
      });
    },

    // watch checkbox status
    haveAccept(key, reqid, item, str) {
      const refkey = `${key}_${str}_${reqid}`;
      Object.keys(this.requirements).forEach((val, i) => {
        // get object being updated
        if (this.requirements[i].user_req_id === reqid) {
          // check what is being update
          this.requirements[i].have_status = this.setStatus(this.$refs[refkey][0].internalValue);
        }
      });
    },

    // set have_status based on checkbox
    setStatus(val) {
      let checkstatus = "";
      const checkval = val;

      if (checkval === true) {
        checkstatus = 1;
      } else {
        checkstatus = 0; // reset have_status
      }

      return checkstatus;
    },

    // set checkbox value
    setReject(status) {
      let setstatus = "";
      if (status === 2) {
        setstatus = true;
      }

      return setstatus;
    },

    // set checkbox value
    setOkay(status) {
      let setstatus = "";
      if (status === 1) {
        setstatus = true;
      }
      return setstatus;
    },
  },
  computed: {
    ...mapGetters([
      "useruploadapi",
      "getrequirementsapi",
      "nofileupload",
      "readhavestatus",
      "formrules",
      "requirements",
      "requirementstatus",
      "links",
      "eis",
      "req_user_id",
      "eisfieldstatus",
      "dependents",
      "viewfileapi",
      "work_experience",
      "character_reference",
    ]),
    fileRules() {
      return [
        // TODO show failed upload files
        (value) =>
          !value || value.size < this.formrules.filesize || "File should be less than 5 MB.",
        (value) => {
          if (!value) {
            return true;
          }

          const filex = `.${value.name.split(".").pop()}`;

          const filetype = value.type;

          if (!this.formrules.acceptfiles.includes(filex) && !filetype.includes("image")) {
            return "Invalid File Type";
          }
          return true;
        },
      ];
    },
  },
  components: {
    UserDialog,
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .modal__body {
    align-items: flex-end !important;

    .modal__title {
      align-self: flex-start !important;
    }
  }
}
.modal__btn {
  display: flex !important;
  align-items: flex-end !important;
  .btn__blue {
    display: block;
    margin: 10px 30px 30px;
  }
  .btn__white {
    padding-top: 18px;
    padding-bottom: 18px;
    border: #4478f6 solid 1px;
    display: block;
    margin: 10px 0 30px;
  }
}
</style>
