const data = {
  links: {
    related_party: '',
    accept_user: '/files/Acceptable+Use+Policy+for+Company+Issued+Devices-+917Ventures.pdf',
    download_all: '/files/govt_forms.zip',
    imgsample: 'https://welcome-dev.area917.ph',
    read_more_first_day: 'https://www.917ventures.com',
  },
};

export const getters = {
  links: (state) => state.links,
};

export default {
  state: data,
  getters,
};
