import Vue from "vue";
import Vuex from "vuex";

import fields from "./modules/fields"; // editable fields
import services from "./modules/services"; // list of apis
import vars from "./modules/var"; // global static variable
import google from "./modules/google"; // for admin sign in
import modal from "./modules/modal"; // dynamic modal (admin)
import validator from "./modules/validator";
import loading from "./modules/loading"; // show or hide loading gif
import getrequirements from "./modules/getrequirements";
import user from "./modules/user";
import userprofile from "./modules/userprofile";
import links from "./modules/links";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    fields,
    services,
    vars,
    google,
    modal,
    validator,
    loading,
    getrequirements,
    user,
    userprofile,
    links,
  },
});
