<template>
  <div class="admin-header d-flex flex-row flex-wrap align-center justify-space-between">
      <div class="admin-header__details d-flex align-center">
          <img :src="hravatarboy" alt="admin avatar">
          <p class="admin-header__txt ma-0 ml-3">Hi {{ adminFirstName }}!</p>
      </div>
      <div>
          <router-link :to="{ name: 'admin-home'}" class="admin-header__txt ma-0">HR Portal</router-link>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdminHeader',
  data() {
    return {
      // image
      hravatarboy: '/img/icons/web/hr-avatar-boy.svg',
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'adminFirstName',
    ]),
  },
  components: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
