<template>
  <div class="user-login form">
      <p class="user-login__title">Sign In</p>
      <div class="user-login__div">
          <div class="user-login__error" v-if="loginstatus">Login failed. Please check your credentials.</div>
          <div class="user-login__username">
            <v-text-field
                v-model.trim="$v.userlogin.username.$model"
                class="form-control form__input form__input--text form__input--text-name form__input--sm"
                :class="status($v.userlogin.username)"
                :error-messages="usernameErrors"
                required
                outlined
                autocomplete="off"
                :maxlength=maxlen.username
                @input="$v.userlogin.username.$touch()"
                @blur="$v.userlogin.username.$touch()"
                @keyup.enter="submitLogin"
                placeholder="username"
            ></v-text-field>
          </div>
          <div class="user-login__password">
            <v-text-field
                v-model.trim="$v.userlogin.password.$model"
                class="form-control form__input form__input--text form__input--text-name form__input--sm"
                :class="status($v.userlogin.password)"
                :error-messages="passwordErrors"
                type="password"
                required
                outlined
                autocomplete="off"
                :maxlength=maxlen.password
                @input="$v.userlogin.password.$touch()"
                @blur="$v.userlogin.password.$touch()"
                @keyup.enter="submitLogin"
                placeholder="password"
            ></v-text-field>
          </div>
          <div class="form__btn-div">
              <button type="button" class="form__btn btn__blue" v-bind:disabled="$v.userlogin.$invalid || formsubmit ? true : false" @click="submitLogin" >{{ formsubmittxt }}</button>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import {
  required, maxLength, minLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import axios from 'axios';
// import api from '../../utils/api';

Vue.use(Vuelidate);

const max = {
  username: 100,
  password: 100,
};
const min = {
  username: 3,
  password: 8,
};

export default {
  name: 'UserLoginForm',
  data() {
    return {
      loginstatus: false,
      userlogin: {
        username: '',
        password: '',
      },
      maxlen: {
        username: max.username,
        password: max.password,
      },
      formsubmit: false,
      formsubmittxt: 'Sign in',

      // MODAL SUCCESS txt
      successlogin: {
        status: true,
        title: 'Success',
        msg: 'You have successfully logged in.',
        button: 'GO TO DASHBOARD',
        link: 'user-home',
        params: '',
        close: 'user-home',
        success: true,
      },
      failedlogin: {
        status: true,
        title: 'Oops!',
        msg: 'Something went wrong. Please try again.',
        button: 'GO BACK',
        link: '',
        params: '',
        close: '',
        success: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'userloginapi',
      // 'formfield',
      // 'adminsubmitemployee',
      // 'adminIsLogin',
    ]),
    usernameErrors() {
      const errors = [];
      const maxerrtxt = `This field must be at most ${max.username} characters long.`;
      const minerrtxt = `This field must be at least ${min.username} characters`;
      if (!this.$v.userlogin.username.$dirty) return errors;
      !this.$v.userlogin.username.maxLength && errors.push(maxerrtxt);
      !this.$v.userlogin.username.minLength && errors.push(minerrtxt);
      !this.$v.userlogin.username.required && errors.push('This field is required. Please try again.');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const maxerrtxt = `This field must be at most ${max.password} characters long.`;
      const minerrtxt = `This field must be at least ${min.password} characters`;
      if (!this.$v.userlogin.password.$dirty) return errors;
      !this.$v.userlogin.password.maxLength && errors.push(maxerrtxt);
      !this.$v.userlogin.password.minLength && errors.push(minerrtxt);
      !this.$v.userlogin.password.required && errors.push('This field is required. Please try again.');
      return errors;
    },
  },
  methods: {
    status(validation) { // vuelidator
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
    routeToPage(pageStr) {
      this.$router.push({ name: pageStr });
    },
    submitLogin() {
      // process login
      // check if eis is true : show welcome dialog
      this.$store.dispatch('loadingTrue');
      const request = {
        username: this.userlogin.username,
        password: this.userlogin.password,
      };
      // console.log(request);

      // add skipAuthRefresh flag to skip interceptor
      // api.postData(this.userloginapi, request, { skipAuthRefresh: true }).then(
      axios.post(this.userloginapi, request, { skipAuthRefresh: true }).then(
        (res) => {
          // console.log(res);
          if (res.data.success) {
            // show success
            if (res.data.result) {
              if (res.data.result.role && res.data.result.user_id) {
                this.$store.dispatch('setUserInfo', {
                  user_role: res.data.result.role,
                  user_id: res.data.result.user_id,
                });
                if (res.data.result.auth) {
                  this.$store.dispatch('setUserAuth', {
                    access_token: res.data.result.auth.access_token,
                    refresh_token: res.data.result.auth.refresh_token,
                  });
                }
                this.$store.dispatch('googleSignOut');
                this.routeToPage('user-home');
              } else {
                this.routeToPage('user-failed');
              }
            }
          } else {
            this.routeToPage('user-failed');
          }
        }, (error) => {
          // console.log(error.response);
          if (error.response && error.response.status === 403) {
            this.routeToPage('user-login-forbidden');
          } else {
            this.routeToPage('user-failed');
          }
        },
      );
    },
  },
  validations: {
    userlogin: {
      username: {
        required,
        maxLength: maxLength(max.username),
        minLength: minLength(min.username),
      },
      password: {
        required,
        maxLength: maxLength(max.password),
        minLength: minLength(min.password),
      },
    },
  },
  components: {
  },
};
</script>
