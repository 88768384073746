const data = {
  adminloginapi: 'google_signin',
  adminsubmitemployee: 'create_user',
  adminupdateemployee: 'admin-update-employee',
  getrequirementsapi: 'get_user_requirements',
  getprofileapi: 'get_user_profile',
  getusersapi: 'list_users',
  getdatetodayapi: 'get_date',
  getuserscalendarapi: 'get_user_status',
  getuserrequirementsrapi: 'get_user_requirements',
  refreshtokenapi: 'get_access_token',
  updateprofileapi: 'update_user',
  useruploadapi: 'save_requirements',
  userloginapi: 'login',
  viewfileapi: 'view_file',
  testing401api: 'http://localhost:4000/user-login401',
};

export const getters = {
  adminloginapi: (state) => state.adminloginapi,
  adminsubmitemployee: (state) => state.adminsubmitemployee,
  adminupdateemployee: (state) => state.adminupdateemployee,
  getrequirementsapi: (state) => state.getrequirementsapi,
  getprofileapi: (state) => state.getprofileapi,
  getusersapi: (state) => state.getusersapi,
  getdatetodayapi: (state) => state.getdatetodayapi,
  getuserscalendarapi: (state) => state.getuserscalendarapi,
  getuserrequirementsrapi: (state) => state.getuserrequirementsrapi,
  refreshtokenapi: (state) => state.refreshtokenapi,
  updateprofileapi: (state) => state.updateprofileapi,
  useruploadapi: (state) => state.useruploadapi,
  userloginapi: (state) => state.userloginapi,
  viewfileapi: (state) => state.viewfileapi,
  testing401api: (state) => state.testing401api,
};

export default {
  state: data,
  getters,
};
