<template>
  <div class="user-home">
    <div class="container-left col-sm-12" v-if="profilestatus">
      <div class="home__header d-flex justify-space-between align-center">
         <p class="home__title">Dashboard</p>
         <button class="btn__link f-color--blue" @click="logout">logout</button>
      </div>
      <div class="profile">
        <div class="row">
          <div class="profile__head d-flex align-center">
              <img :src=this.userimg alt="profile image" class="profile__img mr-7">
              <div class="profile__div d-flex flex-row align-center justify-space-between">
                <div class="profile__details">
                  <p class="ma-0 profile__fname">{{userprofile.first_name}}</p>
                  <p class="ma-0 profile__name">{{userprofile.last_name}}, {{userprofile.first_name}} {{userprofile.middle_initial}}</p>
                  <p class="ma-0 profile__position">{{userprofile.position}}</p>
                  <p class="profile__tstart">Target Start Date: <br> <span>{{userprofile.target_start_date | momentFull }}</span></p>
                </div>
                <div class="profile__target-div">
                  <p class="profile__tstart">Target Start Date:</p>
                  <div v-bind:style="calendarbg" class="calendar-bg">
                    <p class="month"> {{userprofile.target_start_date | momentMonth }}</p>
                    <p class="date"> {{userprofile.target_start_date | momentDate }}</p>
                    <p class="year"> {{userprofile.target_start_date | momentYear }}</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="home__req--desc">
        <div class="profile__progress">
          <div class="d-flex align-end justify-space-between profile__progress--desc">
            <p class="ma-0 progress__txt--black">Onboarding Requirements</p>
            <p class="ma-0 progress__txt f-color--light-black">{{this.userprofile.progress}}%</p>
          </div>
            <v-progress-linear
              v-model="this.userprofile.progress"
              color="#71C5F0"
              class="progress"
              :class="{'progress__inline--20': this.userprofile.progress < 30,'progress__inline--50': this.userprofile.progress > 30 && this.userprofile.progress < 60,'progress__inline--100': this.userprofile.progress > 60}"
            ></v-progress-linear>
        </div>
        <div class="desc--first d-flex align-center justify-center px-10">
          <p class="mx-0 my-6 text-center txt-reminded">Please be reminded to submit all hardcopy requirements before your 1st day starts. Complete your onboarding checklist. Click the button to continue.</p>
          <router-link :to="{name:'user-requirements'}" class="btn__blue">SUBMIT REQUIREMENTS</router-link>
        </div>
        <div class="desc--second">
          <div class="download__div">
            <img :src="zipimg" alt="zip image" class="download__img">
            <p class="ma-0 download__txt">Download all the government forms here.</p>
            <a :href="links.download_all" target="_blank" class="btn__blue"> DOWNLOAD HERE</a>

          </div>
        </div>
      </div>
      <div class="user-home__footer d-flex justify-space-between">
        <div class="home__dayone">
          <p class="ma-0 home__dayone--title">Day One</p>
          <div class="home__dayone--half" v-if="dayone">
            <p>More details in the coming days. Wait for our announcement.</p>
          </div>
          <div class="home__dayone--full"  v-if="!dayone">
            <div class="first">
              <p class="first__title">Are you ready to build the future? </p>
              <p class="first__msg">Click 'Read More' to know about 917Ventures.</p>
            </div>
            <div class="second">
              <a :href="links.read_more_first_day" target="_blank" class="btn__blue btn__line second__btn">Read More</a>
            </div>
          </div>
        </div>
        <div class="home__questions">
            <p class="title-txt">Do you have any questions?</p>
            <p class="msg">Message our HR representative:</p>
            <div class="hr-details d-flex align-center flex-column text-center">
              <p class="name">{{hr.name}}, <span>{{hr.position}}</span></p>
              <a :href="`tel:${hr.telno}`" class="link__no">{{hr.telno}}</a>
              <p>or</p>
              <a :href="`mailto:${hr.email}`" class="btn__blue second__btn">Email Us</a>
            </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="welcome.status" persistent no-click-animation>
      <div class="modal__body modal__welcome tk-museo-sans-rounded">
          <p class="modal__title">{{ welcome.title }}</p>
          <p class="modal__msg" v-html="welcome.msg"></p>

          <div class="modal__btn">
            <button @click="closeDialog" class="form__btn btn__blue">
              {{ welcome.button }}
            </button>
          </div>
          <a :href="links.download_all" target="_blank" class="modal__footer">
            <img :src="iconzip" alt="zip file icon" class="modal__footer--img">
            <p class="ma-0">{{welcome.footertxt}}</p>
          </a>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import applocalstorage from '../../utils/applocalstorage';

// Service for storing data to localstorage
const localStorageService = applocalstorage.getService();

export default {
  name: 'UserHome',
  data() {
    return {
      userimg: '/img/profile-default.png',
      zipimg: '/img/icons/web/zip-white.svg',
      dayone: true,
      showwelcome: false,
      hr: {
        name: 'Regi Yumul',
        position: 'HR Recruitment',
        telno: '+63917 688 1300',
        email: 'workplace@917ventures.com',
      },
      calendarbg: {
        background: "url('img/bg/calendar-bg.svg')",
      },
      iconzip: '/img/icons/web/zip-white.svg',
      welcome: {
        status: false,
        title: 'Welcome',
        msg: '<p><span class="font-bold">TERI</span> is 917Ventures\' onboarding portal, to make things easy for you. Please accomplish your checklist by submitting your pre-employment requirements.</p>',
        button: 'START MY ONBOARDING',
        link: 'user-requirements',
        params: '',
        close: '',
        footerlink: '/',
        footertxt: 'Click here to download all government forms.',
      },
    };
  },
  computed: {
    ...mapGetters([
      'links',
      'getprofileapi',
      'userprofile',
      'profilestatus',
      'eis',
    ]),
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    closeDialog() {
      this.welcome.status = false;
    },
    computeDayOne(startdate) {
      this.dayone = moment(startdate).isBefore(moment().format('YYYY-MM-DD'));
    },
    getProfile() {
      this.$store.dispatch('loadingTrue');
      if (localStorageService.getUserId()) {
        const request = {
          user_id: localStorageService.getUserId(),
        };
        this.$store.dispatch('getUserProfile', request).then(() => {
          // check if progress is zero
          if (this.userprofile.progress === 0 && Object.keys(this.eis).length === 0) {
            this.welcome.status = true;
          }
          // Compute day one
          this.computeDayOne(this.userprofile.target_start_date);

          this.$store.dispatch('loadingFalse');
        });
      } else {
        this.$store.dispatch('loadingFalse');
        this.$router.push({
          name: 'user-login',
        });
      }
    },
    logout() {
      this.resetUserData();
      this.$router.push({ name: 'user-login' });
    },
    resetUserData() {
      this.$store.dispatch('resetUserAuth');
      this.$store.dispatch('resetUserInfo');
      this.$store.dispatch('resetUserProfile');
    },
  },
  filters: {
    momentDate(date) {
      return moment(date).format('DD');
    },
    momentYear(date) {
      return moment(date).format('YYYY');
    },
    momentMonth(date) {
      return moment(date).format('MMM');
    },
    momentFull(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
  },
};
</script>
