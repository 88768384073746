<template>
  <div class="glogout">
    <div @click="onSignedOut">
       <GoogleLogin :params="params" :logoutButton=true>Logout</GoogleLogin>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { GoogleLogin } from 'vue-google-login';

export default {
  name: 'GoogleLogout',
  data() {
    return {
      // google btn
      params: {
        client_id: this.client_id,
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  components: {
    GoogleLogin,
  },
  methods: {
    onSignedOut() {
      this.$store.dispatch('resetAdminState');
      this.$store.dispatch('resetUserAuth');
      setTimeout(() => {
        this.$router.push({
          name: 'admin-login',
        });
      }, 600);
    },
  },
  computed: {
    ...mapGetters([
      'client_id',
    ]),
  },
};
</script>
