<template>
  <div class="glogin">
    <div class="glogin__inner">
      <div class="glogin__body">
        <img :src=terilogo alt="teri logo" class="glogin__logo">
        <p class="text-center mt-3 glogin__title">Talent Entry &<br> Recruitment Intelligence</p>
      </div>
      <UserLoginForm></UserLoginForm>
    </div>
  </div>
</template>

<script>

import UserLoginForm from '../../components/User/UserLoginForm.vue';

export default {
  name: 'UserLogin',
  data() {
    return {
      terilogo: '/img/logo/teri.svg',
    };
  },
  components: {
    UserLoginForm,
  },
};
</script>
