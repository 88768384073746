<template>
  <div
    class="eis"
    :class="{ 'eis--disabled': eis_have_status || adminEis }"
    v-if="requirementstatus"
    id="id_EIS"
  >
    <v-form ref="eisform">
      <div class="form">
        <!-- PERSONAL INFORMATION -->
        <div class="eis__row eis__personal">
          <p class="form__section--title">Personal Information</p>
          <div class="form__row row">
            <div :class="eisfield.personal.last_name.columns">
              <p class="form__input--label">{{ eisfield.personal.last_name.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.last_name.$model"
                :class="[eisfield.personal.last_name.class, status($v.eisval.last_name.$model)]"
                :error-messages="lastNameErrors"
                required
                outlined
                :disabled="eisval.last_name ? true : false"
                autocomplete="off"
                :maxlength="formrules.last_name"
                @keyup="checkEISForm"
                @input="$v.eisval.last_name.$touch()"
                @blur="$v.eisval.last_name.$touch()"
                :placeholder="eisfield.personal.last_name.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.first_name.columns">
              <p class="form__input--label">{{ eisfield.personal.first_name.title }}</p>
              <v-text-field
                :class="[eisfield.personal.first_name.class, status($v.eisval.first_name.$model)]"
                v-model.trim="$v.eisval.first_name.$model"
                required
                outlined
                autocomplete="off"
                :disabled="eisval.first_name ? true : false"
                :maxlength="formrules.first_name"
                :error-messages="firstNameErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.first_name.$touch()"
                @blur="$v.eisval.first_name.$touch()"
                :placeholder="eisfield.personal.first_name.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.middle_name.columns">
              <p class="form__input--label">{{ eisfield.personal.middle_name.title }}</p>
              <v-text-field
                :class="[eisfield.personal.middle_name.class, status($v.eisval.middle_name.$model)]"
                v-model.trim="$v.eisval.middle_name.$model"
                outlined
                autocomplete="off"
                :maxlength="formrules.middle_name"
                :error-messages="middleNameErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.middle_name.$touch()"
                @blur="$v.eisval.middle_name.$touch()"
                :placeholder="eisfield.personal.middle_name.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.suffix.columns">
              <p class="form__input--label">{{ eisfield.personal.suffix.title }}</p>
              <v-text-field
                :class="eisfield.personal.suffix.class"
                v-model.trim="$v.eisval.suffix.$model"
                outlined
                autocomplete="off"
                @keyup="checkEISForm"
                :placeholder="eisfield.personal.suffix.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.nickname.columns">
              <p class="form__input--label">{{ eisfield.personal.nickname.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.nickname.$model"
                required
                outlined
                autocomplete="off"
                :class="[eisfield.personal.nickname.class, status($v.eisval.nickname.$model)]"
                :maxlength="formrules.nickname"
                :error-messages="nicknameErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.nickname.$touch()"
                @blur="$v.eisval.nickname.$touch()"
                :placeholder="eisfield.personal.nickname.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.gender.columns">
              <p class="form__input--label">{{ eisfield.personal.gender.title }}</p>
              <v-select
                :items="eisfield.personal.gender.list"
                v-model.trim="$v.eisval.gender.$model"
                item-text="text"
                item-value="value"
                outlined
                :class="[eisfield.personal.gender.class, status($v.eisval.gender.$model)]"
                :error-messages="genderErrors"
                @change="checkEISForm"
                @input="$v.eisval.gender.$touch()"
                @blur="$v.eisval.gender.$touch()"
              ></v-select>
            </div>
            <div :class="eisfield.personal.birthday.columns">
              <p class="form__input--label">{{ eisfield.personal.birthday.title }}</p>
              <v-menu v-model="birthdate" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="$v.eisval.birthday.$model"
                    :class="[eisfield.personal.birthday.class, status($v.eisval.birthday.$model)]"
                    :error-messages="birthdayErrors"
                    append-icon="event"
                    ref="calendardate_update"
                    clearable
                    required
                    outlined
                    readonly
                    v-on="on"
                    @change="checkEISForm"
                    :placeholder="eisfield.personal.birthday.placeholder"
                    @click:clear="$v.eisval.birthday = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model.trim="$v.eisval.birthday.$model"
                  class="form__date-pick"
                  @change="eisBirthday"
                  :max="datetoday"
                ></v-date-picker>
              </v-menu>
            </div>
            <div :class="eisfield.personal.age.columns">
              <p class="form__input--label">{{ eisfield.personal.age.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.age.$model"
                required
                outlined
                autocomplete="off"
                disabled
                :maxlength="formrules.age"
                :class="[eisfield.personal.age.class, status($v.eisval.age.$model)]"
                :error-messages="ageErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.age.$touch()"
                @blur="$v.eisval.age.$touch()"
                :placeholder="eisfield.personal.age.placeholder"
              ></v-text-field>
            </div>
            <div :class="eisfield.personal.civil_status.columns">
              <p class="form__input--label">{{ eisfield.personal.civil_status.title }}</p>
              <v-select
                :items="eisfield.personal.civil_status.list"
                v-model.trim="$v.eisval.civil_status.$model"
                item-text="text"
                item-value="value"
                outlined
                :class="[
                  eisfield.personal.civil_status.class,
                  status($v.eisval.civil_status.$model),
                ]"
                :error-messages="civilStatusErrors"
                @change="checkEISForm"
                @input="$v.eisval.civil_status.$touch()"
                @blur="$v.eisval.civil_status.$touch()"
              ></v-select>
            </div>
            <div :class="eisfield.personal.country_of_birth.columns">
              <p class="form__input--label">{{ eisfield.personal.country_of_birth.title }}</p>
              <v-select
                :items="eisfield.personal.country_of_birth.list"
                v-model.trim="$v.eisval.country_of_birth.$model"
                item-text="text"
                item-value="value"
                outlined
                :class="[
                  eisfield.personal.country_of_birth.class,
                  status($v.eisval.country_of_birth.$model),
                ]"
                :error-messages="countryErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.country_of_birth.$touch()"
                @blur="$v.eisval.country_of_birth.$touch()"
              ></v-select>
            </div>
            <div :class="eisfield.personal.mailing_address.columns">
              <p class="form__input--label">{{ eisfield.personal.mailing_address.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.mailing_address.$model"
                required
                outlined
                autocomplete="off"
                :maxlength="formrules.mailing_address"
                :class="[
                  eisfield.personal.mailing_address.class,
                  status($v.eisval.mailing_address.$model),
                ]"
                :error-messages="mailingAddressErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.mailing_address.$touch()"
                @blur="$v.eisval.mailing_address.$touch()"
                :placeholder="eisfield.personal.mailing_address.placeholder"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="eis__row eis__row--blue eis__government">
          <p class="form__section--title">Government Agencies</p>
          <div class="form__row row">
            <div :class="eisfield.government.tin.columns">
              <p class="form__input--label">{{ eisfield.government.tin.title }}</p>
              <!-- TODO auto dash number -->
              <v-text-field
                v-model.trim="$v.eisval.tin.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.government.tin.placeholder"
                :maxlength="formrules.tin"
                :class="[eisfield.government.tin.class, status($v.eisval.tin.$model)]"
                :error-messages="tinErrors"
                @keyup="checkTinFormat"
                @change="checkEISForm"
                @input="$v.eisval.tin.$touch()"
                @blur="$v.eisval.tin.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.government.pagibig.columns">
              <p class="form__input--label">{{ eisfield.government.pagibig.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.pagibig.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.government.pagibig.placeholder"
                :maxlength="formrules.pagibig"
                :class="[eisfield.government.pagibig.class, status($v.eisval.pagibig.$model)]"
                :error-messages="pagibigErrors"
                @keyup="checkPagibigFormat"
                @change="checkEISForm"
                @input="$v.eisval.pagibig.$touch()"
                @blur="$v.eisval.pagibig.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.government.sss.columns">
              <p class="form__input--label">{{ eisfield.government.sss.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.sss.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.government.sss.placeholder"
                :maxlength="formrules.sss"
                :class="[eisfield.government.sss.class, status($v.eisval.sss.$model)]"
                :error-messages="sssErrors"
                @keyup="checkSSSFormat"
                @change="checkEISForm"
                @input="$v.eisval.sss.$touch()"
                @blur="$v.eisval.sss.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.government.philhealth.columns">
              <p class="form__input--label">{{ eisfield.government.philhealth.title }}</p>
              <v-text-field
                v-model.trim="$v.eisval.philhealth.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.government.philhealth.placeholder"
                :maxlength="formrules.philhealth"
                :class="[eisfield.government.philhealth.class, status($v.eisval.philhealth.$model)]"
                :error-messages="philhealthErrors"
                @change="checkEISForm"
                @input="$v.eisval.philhealth.$touch()"
                @blur="$v.eisval.philhealth.$touch()"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="eis__row eis__emergency">
          <p class="form__section--title">Emergency Contacts</p>
          <div class="form__row row">
            <div :class="eisfield.emergency.emergency_contact_name.columns">
              <p class="form__input--label">
                {{ eisfield.emergency.emergency_contact_name.title }}
              </p>
              <v-text-field
                v-model.trim="$v.eisval.emergency_contact_name.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.emergency.emergency_contact_name.placeholder"
                :maxlength="formrules.emergency_contact_name"
                :class="[
                  eisfield.emergency.emergency_contact_name.class,
                  status($v.eisval.emergency_contact_name.$model),
                ]"
                :error-messages="eContactNameErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.emergency_contact_name.$touch()"
                @blur="$v.eisval.emergency_contact_name.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.emergency.emergency_contact_relationship.columns">
              <p class="form__input--label">
                {{ eisfield.emergency.emergency_contact_relationship.title }}
              </p>
              <v-text-field
                v-model.trim="$v.eisval.emergency_contact_relationship.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.emergency.emergency_contact_relationship.placeholder"
                :maxlength="formrules.emergency_contact_relationship"
                :class="[
                  eisfield.emergency.emergency_contact_relationship.class,
                  status($v.eisval.emergency_contact_relationship.$model),
                ]"
                :error-messages="eContactRelationErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.emergency_contact_relationship.$touch()"
                @blur="$v.eisval.emergency_contact_relationship.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.emergency.emergency_contact_number.columns">
              <p class="form__input--label">
                {{ eisfield.emergency.emergency_contact_number.title }}
              </p>
              <v-text-field
                v-model.trim="$v.eisval.emergency_contact_number.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.emergency.emergency_contact_number.placeholder"
                :maxlength="formrules.emergency_contact_number"
                :class="[
                  eisfield.emergency.emergency_contact_number.class,
                  status($v.eisval.emergency_contact_number.$model),
                ]"
                :error-messages="eContactNumberErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.emergency_contact_number.$touch()"
                @blur="$v.eisval.emergency_contact_number.$touch()"
              ></v-text-field>
            </div>
            <div :class="eisfield.emergency.emergency_contact_address.columns">
              <p class="form__input--label">
                {{ eisfield.emergency.emergency_contact_address.title }}
              </p>
              <v-text-field
                v-model.trim="$v.eisval.emergency_contact_address.$model"
                required
                outlined
                autocomplete="off"
                :placeholder="eisfield.emergency.emergency_contact_address.placeholder"
                :maxlength="formrules.emergency_contact_address"
                :class="[
                  eisfield.emergency.emergency_contact_address.class,
                  status($v.eisval.emergency_contact_address.$model),
                ]"
                :error-messages="eContactAddressErrors"
                @keyup="checkEISForm"
                @input="$v.eisval.emergency_contact_address.$touch()"
                @blur="$v.eisval.emergency_contact_address.$touch()"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="eis__row eis__row--blue eis__dependents">
          <p class="form__section--title">Dependents Profile</p>
          <div class="form__row row">
            <div v-for="(v, index) in $v.dependents.$each.$iter" :key="index" class="row">
              <div :class="eisfield.dependents.dep_name.columns">
                <p class="form__input--label">{{ eisfield.dependents.dep_name.title }}</p>
                <v-text-field
                  :class="eisfield.dependents.dep_name.class"
                  v-model.trim="v.name.$model"
                  required
                  outlined
                  :maxlength="formrules.dep_name"
                  autocomplete="off"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.dependents.dep_name.placeholder"
                ></v-text-field>
              </div>
              <div :class="eisfield.dependents.dep_relationship.columns">
                <p class="form__input--label">{{ eisfield.dependents.dep_relationship.title }}</p>
                <v-select
                  :items="eisfield.dependents.dep_relationship.list"
                  v-model.trim="v.relationship.$model"
                  item-text="text"
                  item-value="value"
                  required
                  outlined
                  autocomplete="off"
                  :placeholder="eisfield.dependents.dep_relationship.placeholder"
                  :class="[
                    eisfield.dependents.dep_relationship.class,
                    status(v.relationship.$model),
                  ]"
                  @keyup="checkEISForm"
                  @input="v.relationship.$touch()"
                  @blur="v.relationship.$touch()"
                ></v-select>
              </div>
              <div :class="eisfield.dependents.dep_birthdate.columns">
                <p class="form__input--label">{{ eisfield.dependents.dep_birthdate.title }}</p>
                <v-menu
                  :v-model="`dep_birthdate${index}`"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="v.birthdate.$model"
                      :class="eisfield.dependents.dep_birthdate.class"
                      append-icon="event"
                      ref="calendardate_update"
                      clearable
                      required
                      outlined
                      readonly
                      v-on="on"
                      :placeholder="eisfield.dependents.dep_birthdate.placeholder"
                      @keyup="checkEISForm"
                      @click:clear="v.birthdate.$model = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="v.birthdate.$model"
                    class="form__date-pick"
                    :max="datetoday"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div :class="eisfield.dependents.dep_age.columns">
                <p class="form__input--label">{{ eisfield.dependents.dep_age.title }}</p>
                <v-text-field
                  :class="eisfield.dependents.dep_age.class"
                  v-model.trim="v.age.$model"
                  required
                  outlined
                  :maxlength="formrules.dep_age"
                  autocomplete="off"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.dependents.dep_age.placeholder"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="eis__row eis__work-exp">
          <p class="form__section--title">Prior Work Experience</p>
          <div class="form__row row">
            <div v-for="(v, index) in $v.work_experience.$each.$iter" :key="index" class="row">
              <div :class="eisfield.work_exp.company_name.columns">
                <p class="form__input--label">{{ eisfield.work_exp.company_name.title }}</p>
                <v-text-field
                  :class="eisfield.work_exp.company_name.class"
                  v-model.trim="v.company_name.$model"
                  required
                  outlined
                  :maxlength="formrules.company_name"
                  autocomplete="off"
                  :placeholder="eisfield.work_exp.company_name.placeholder"
                  @keyup="checkEISForm"
                ></v-text-field>
              </div>
              <div :class="eisfield.work_exp.last_position_held.columns">
                <p class="form__input--label">{{ eisfield.work_exp.last_position_held.title }}</p>
                <v-text-field
                  :class="eisfield.work_exp.last_position_held.class"
                  v-model.trim="v.last_position_held.$model"
                  required
                  outlined
                  :maxlength="formrules.last_position_held"
                  autocomplete="off"
                  :placeholder="eisfield.work_exp.last_position_held.placeholder"
                  @keyup="checkEISForm"
                ></v-text-field>
              </div>
              <div :class="eisfield.work_exp.inclusive_start_date.columns">
                <p class="form__input--label">{{ eisfield.work_exp.inclusive_start_date.title }}</p>
                <v-menu
                  :v-model="`incl_start${index}`"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="v.inclusive_start_date.$model"
                      :class="eisfield.work_exp.inclusive_start_date.class"
                      append-icon="event"
                      ref="calendardate_update"
                      clearable
                      required
                      outlined
                      readonly
                      v-on="on"
                      :placeholder="eisfield.work_exp.inclusive_start_date.placeholder"
                      @keyup="checkEISForm"
                      @click:clear="v.inclusive_start_date.$model = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="v.inclusive_start_date.$model"
                    class="form__date-pick"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div :class="eisfield.work_exp.inclusive_end_date.columns">
                <p class="form__input--label">{{ eisfield.work_exp.inclusive_end_date.title }}</p>
                <v-menu
                  :v-model="`incl_end${index}`"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="v.inclusive_end_date.$model"
                      :class="eisfield.work_exp.inclusive_end_date.class"
                      append-icon="event"
                      ref="calendardate_update"
                      clearable
                      required
                      outlined
                      readonly
                      v-on="on"
                      :placeholder="eisfield.work_exp.inclusive_end_date.placeholder"
                      @click:clear="v.inclusive_end_date.$model = null"
                      @keyup="checkEISForm"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="v.inclusive_end_date.$model"
                    class="form__date-pick"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div :class="eisfield.work_exp.immediate_supervisor.columns">
                <p class="form__input--label">{{ eisfield.work_exp.immediate_supervisor.title }}</p>
                <v-text-field
                  :class="eisfield.work_exp.immediate_supervisor.class"
                  v-model.trim="v.immediate_supervisor.$model"
                  required
                  outlined
                  :maxlength="formrules.immediate_supervisor"
                  autocomplete="off"
                  :placeholder="eisfield.work_exp.immediate_supervisor.placeholder"
                  @keyup="checkEISForm"
                ></v-text-field>
              </div>
              <div :class="eisfield.work_exp.reason_for_leaving.columns">
                <p class="form__input--label">{{ eisfield.work_exp.reason_for_leaving.title }}</p>
                <v-text-field
                  :class="eisfield.work_exp.reason_for_leaving.class"
                  v-model.trim="v.reason_for_leaving.$model"
                  required
                  outlined
                  :maxlength="formrules.reason_for_leaving"
                  autocomplete="off"
                  :placeholder="eisfield.work_exp.reason_for_leaving.placeholder"
                  @keyup="checkEISForm"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="eis__row eis__row--blue eis__char-ref">
          <p class="form__section--title">Character Reference</p>
          <div class="form__row row">
            <div v-for="(v, index) in $v.character_reference.$each.$iter" :key="index" class="row">
              <div class="col-xs-12 form__input col-sm-12">
                <p>Reference {{ Number(index) + 1 }}</p>
              </div>
              <div :class="eisfield.character_reference.name.columns">
                <p class="form__input--label">{{ eisfield.character_reference.name.title }}</p>
                <v-text-field
                  :class="eisfield.character_reference.name.class"
                  v-model.trim="v.name.$model"
                  required
                  outlined
                  :maxlength="formrules.emergency_contact_name"
                  autocomplete="off"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.character_reference.name.placeholder"
                ></v-text-field>
              </div>
              <div :class="eisfield.character_reference.relationship.columns">
                <p class="form__input--label">
                  {{ eisfield.character_reference.relationship.title }}
                </p>
                <v-text-field
                  :class="eisfield.character_reference.relationship.class"
                  v-model.trim="v.relationship.$model"
                  required
                  outlined
                  autocomplete="off"
                  :maxlength="formrules.emergency_contact_relationship"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.character_reference.relationship.placeholder"
                ></v-text-field>
              </div>
              <div :class="eisfield.character_reference.company.columns">
                <p class="form__input--label">{{ eisfield.character_reference.company.title }}</p>
                <v-text-field
                  :class="eisfield.character_reference.company.class"
                  v-model.trim="v.company.$model"
                  required
                  outlined
                  autocomplete="off"
                  :maxlength="formrules.company_name"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.character_reference.company.placeholder"
                ></v-text-field>
              </div>
              <div :class="eisfield.character_reference.contact_number.columns">
                <p class="form__input--label">
                  {{ eisfield.character_reference.contact_number.title }}
                </p>
                <v-text-field
                  :class="eisfield.character_reference.contact_number.class"
                  v-model.trim="v.contact_number.$model"
                  required
                  outlined
                  autocomplete="off"
                  :maxlength="formrules.emergency_contact_number"
                  @keyup="checkEISForm"
                  :placeholder="eisfield.character_reference.contact_number.placeholder"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { mapGetters } from "vuex";
import moment from "moment";
import { required, maxLength, numeric, minValue } from "vuelidate/lib/validators";

Vue.use(Vuelidate);

export default {
  name: "UserHome",
  props: ["eisval"],
  data() {
    return {
      datetoday: new Date().toISOString().substr(0, 10),
      birthdate: false,
      dep_birthdate0: false,
      dep_birthdate1: false,
      dep_birthdate2: false,
      incl_start0: false,
      incl_end0: false,
      incl_start1: false,
      incl_end1: false,
      incl_start2: false,
      incl_end2: false,
      tinval: this.eisval.tin,
      adminEis: false,
    };
  },
  computed: {
    ...mapGetters([
      "requirements",
      "requirementstatus",
      "eisfield",
      "eis_have_status",
      "formfield",
      "formrules",
      "dependents",
      "work_experience",
      "character_reference",
    ]),
    // Personal Info
    lastNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${this.formrules.last_name} characters long.`;
      if (!this.$v.eisval.last_name.$dirty) return errors;
      !this.$v.eisval.last_name.maxLength && errors.push(errtxt);
      !this.$v.eisval.last_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${this.formrules.first_name} characters long.`;
      if (!this.$v.eisval.first_name.$dirty) return errors;
      !this.$v.eisval.first_name.maxLength && errors.push(errtxt);
      !this.$v.eisval.first_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    middleNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${this.formrules.middle_name} characters long.`;
      if (!this.$v.eisval.middle_name.$dirty) return errors;
      !this.$v.eisval.middle_name.maxLength && errors.push(errtxt);
      return errors;
    },
    nicknameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${this.formrules.nickname} characters long.`;
      if (!this.$v.eisval.nickname.$dirty) return errors;
      !this.$v.eisval.nickname.maxLength && errors.push(errtxt);
      !this.$v.eisval.nickname.required && errors.push("This field is required. Please try again.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.eisval.gender.$dirty) return errors;
      !this.$v.eisval.gender.required && errors.push("This field is required. Please try again.");
      return errors;
    },
    birthdayErrors() {
      const errors = [];
      if (!this.$v.eisval.birthday.$dirty) return errors;
      !this.$v.eisval.birthday.required && errors.push("This field is required. Please try again.");
      return errors;
    },
    ageErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${this.formrules.age} characters long.`;
      if (!this.$v.eisval.age.$dirty) return errors;
      !this.$v.eisval.age.maxLength && errors.push(errtxt);
      !this.$v.eisval.age.minValue && errors.push("Invalid value. Please try again.");
      !this.$v.eisval.age.numeric && errors.push("Accept numeric charactes only.");
      !this.$v.eisval.age.required && errors.push("This field is required. Please try again.");
      return errors;
    },
    civilStatusErrors() {
      const errors = [];
      if (!this.$v.eisval.civil_status.$dirty) return errors;
      !this.$v.eisval.civil_status.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.eisval.country_of_birth.$dirty) return errors;
      !this.$v.eisval.country_of_birth.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    mailingAddressErrors() {
      const errors = [];
      if (!this.$v.eisval.mailing_address.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.mailing_address} characters long.`;
      !this.$v.eisval.mailing_address.maxLength && errors.push(errtxt);
      !this.$v.eisval.mailing_address.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    // GOVERNMENT
    tinErrors() {
      const errors = [];
      if (!this.$v.eisval.tin.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.tin} characters long.`;
      !this.$v.eisval.tin.maxLength && errors.push(errtxt);
      !this.$v.eisval.tin.required && errors.push("This field is required. Please try again.");
      !this.$v.eisval.tin.tinFormat && errors.push("Invalid Format.");
      return errors;
    },
    pagibigErrors() {
      const errors = [];
      if (!this.$v.eisval.pagibig.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.pagibig} characters long.`;
      !this.$v.eisval.pagibig.maxLength && errors.push(errtxt);
      !this.$v.eisval.pagibig.required && errors.push("This field is required. Please try again.");
      !this.$v.eisval.pagibig.pagibigFormat && errors.push("Invalid Format.");
      return errors;
    },
    sssErrors() {
      const errors = [];
      if (!this.$v.eisval.sss.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.sss} characters long.`;
      !this.$v.eisval.sss.maxLength && errors.push(errtxt);
      !this.$v.eisval.sss.required && errors.push("This field is required. Please try again.");
      !this.$v.eisval.sss.sssFormat && errors.push("Invalid Format.");
      return errors;
    },
    philhealthErrors() {
      const errors = [];
      if (!this.$v.eisval.philhealth.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.philhealth} characters long.`;
      !this.$v.eisval.philhealth.required &&
        errors.push("This field is required. Please try again.");
      !this.$v.eisval.philhealth.maxLength && errors.push(errtxt);
      return errors;
    },
    // EMERGENCY
    eContactNameErrors() {
      const errors = [];
      if (!this.$v.eisval.emergency_contact_name.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.emergency_contact_name} characters long.`;
      !this.$v.eisval.emergency_contact_name.maxLength && errors.push(errtxt);
      !this.$v.eisval.emergency_contact_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    eContactRelationErrors() {
      const errors = [];
      if (!this.$v.eisval.emergency_contact_relationship.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.emergency_contact_relationship} characters long.`;
      !this.$v.eisval.emergency_contact_relationship.maxLength && errors.push(errtxt);
      !this.$v.eisval.emergency_contact_relationship.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    eContactNumberErrors() {
      const errors = [];
      if (!this.$v.eisval.emergency_contact_number.$dirty) return errors;
      !this.$v.eisval.emergency_contact_number.numeric && errors.push("Accepts numeric value.");
      !this.$v.eisval.emergency_contact_number.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    eContactAddressErrors() {
      const errors = [];
      if (!this.$v.eisval.emergency_contact_address.$dirty) return errors;
      const errtxt = `This field must be at most ${this.formrules.emergency_contact_address} characters long.`;
      !this.$v.eisval.emergency_contact_address.maxLength && errors.push(errtxt);
      !this.$v.eisval.emergency_contact_address.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
  },
  methods: {
    status(validation) {
      // vuelidator
      if (validation) {
        return {
          error: validation.$error,
          dirty: validation.$dirty,
        };
      }
      return false;
    },
    checkTinFormat(event) {
      let val = event.target.value;
      let finalVal = "";
      if (val) {
        val = val.split("-").join("");
        finalVal = val.match(/.{1,3}/g).join("-");
      }

      this.eisval.tin = finalVal;
    },
    checkPagibigFormat(event) {
      let val = event.target.value;
      let finalVal = "";
      if (val) {
        val = val.split("-").join("");
        finalVal = val.match(/.{1,3}/g).join("-");
      }

      this.eisval.pagibig = finalVal;
    },
    checkSSSFormat(event) {
      const val = event.target.value;
      let finalVal = "";

      if (val) {
        // val = val.split('-').join('');
        if (val.length === 2) {
          finalVal = `${val}-`;
          if (/^([0-9]{2})/.test(val)) {
            finalVal = `${val}-`;
          } else {
            finalVal = val;
          }
        } else if (val.length === 10) {
          if (/^([0-9]{2})-([0-9]{7})/.test(val)) {
            finalVal = `${val}-`;
          } else {
            finalVal = val;
          }
        } else {
          finalVal = val;
        }
      }

      this.eisval.sss = finalVal;
    },
    checkEISForm() {
      Object.keys(this.requirements).forEach((val, i) => {
        if (this.requirements[i].file_key === "EIS") {
          if (!this.$v.$invalid) {
            this.requirements[i].have_status = 1;
            // eis form is valid
            this.$store.dispatch("eisFieldStatus", true);
          } else {
            this.requirements[i].have_status = 0;
            // eis form is valid
            this.$store.dispatch("eisFieldStatus", false);
          }
        }
      });
    },
    eisBirthday() {
      this.birthdate = false; // close modal on date select
      this.eisval.age = this.getAge(this.eisval.birthday);
      this.$v.eisval.age.$touch();
      if (!this.$v.eisval.age.$invalid) {
        this.$store.dispatch("eisFieldStatus", false);
      } else {
        this.$store.dispatch("eisFieldStatus", true);
      }
      this.checkEISForm();
    },
    getAge(year) {
      moment.tz.add(
        "Asia/Manila|LMT LMT PST PDT JST|fU -84 -80 -90 -90|01232423232|-54m84 2clc0 1vfc4 AL0 cK10 65X0 mXB0 vX0 VK10 1db0|24e6"
      );
      const old = moment.tz(moment(year), "Asia/Manila").format("YYYY");
      const today = moment.tz(moment(), "Asia/Manila").format("YYYY");
      const age = Number(today) - Number(old);
      return age;
    },
    isAdminEis() {
      if (this.$router.currentRoute.name === "admin-employee-eis") {
        this.adminEis = true;
      }
    },
  },
  validations: {
    eisval: {
      last_name: {
        required,
        maxLength: maxLength(100),
      },
      first_name: {
        required,
        maxLength: maxLength(100),
      },
      middle_name: {
        maxLength: maxLength(5),
      },
      suffix: {},
      nickname: {
        required,
        maxLength: maxLength(100),
      },
      birthday: {
        required,
      },
      gender: {
        required,
      },
      age: {
        required,
        numeric,
        maxLength: maxLength(2),
        minValue: minValue(10),
      },
      civil_status: {
        required,
      },
      country_of_birth: {
        required,
      },
      mailing_address: {
        required,
        maxLength: maxLength(100),
      },
      tin: {
        required,
        maxLength: maxLength(15),
        tinFormat(tin) {
          return /[0-9-]{15}/.test(tin);
        },
      },
      pagibig: {
        required,
        maxLength: maxLength(15),
        pagibigFormat(pagibig) {
          return /[0-9-]{15}/.test(pagibig);
        },
      },
      sss: {
        required,
        maxLength: maxLength(12),
        sssFormat(sss) {
          return /^([0-9]{2})-([0-9]{7})-([-0-9]{1}$)/.test(sss);
        },
      },
      philhealth: {
        required,
        maxLength: maxLength(12),
      },
      emergency_contact_name: {
        required,
        maxLength: maxLength(100),
      },
      emergency_contact_relationship: {
        required,
        maxLength: maxLength(100),
      },
      emergency_contact_number: {
        required,
        numeric,
      },
      emergency_contact_address: {
        required,
        maxLength: maxLength(100),
      },
    },
    dependents: {
      $each: {
        name: {},
        relationship: {},
        birthdate: {},
        age: {},
      },
    },
    work_experience: {
      $each: {
        company_name: {},
        last_position_held: {},
        inclusive_start_date: {},
        inclusive_end_date: {},
        immediate_supervisor: {},
        reason_for_leaving: {},
      },
    },
    character_reference: {
      $each: {
        name: {},
        relationship: {},
        company: {},
        contact_number: {},
      },
    },
  },
  mounted() {
    this.isAdminEis();
  },
};
</script>
