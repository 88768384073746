<template>
  <div class="form">
    <v-form ref="form">
      <div class="form__div">
        <div class="d-flex flex-wrap">
          <div class="col-xs-12 form__input--name col-sm-5">
            <p class="form__input--label">{{ formfield.first_name.title }}</p>
            <v-text-field
              v-model.trim="$v.formnew.first_name.$model"
              class="form-control"
              :class="[formfield.first_name.class, status($v.formnew.first_name)]"
              :error-messages="firstNameErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.first_name"
              @input="$v.formnew.first_name.$touch()"
              @blur="$v.formnew.first_name.$touch()"
              @keyup.enter="submitData"
              :placeholder="formfield.first_name.placeholder"
            ></v-text-field>
          </div>
          <div class="col-xs-12 form__input--name col-sm-5">
            <p class="form__input--label">{{ formfield.last_name.title }}</p>
            <v-text-field
              v-model.trim="$v.formnew.last_name.$model"
              class="form-control"
              :class="[formfield.last_name.class, status($v.formnew.last_name)]"
              :error-messages="lastNameErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.last_name"
              @input="$v.formnew.last_name.$touch()"
              @blur="$v.formnew.last_name.$touch()"
              @keyup.enter="submitData"
              :placeholder="formfield.last_name.placeholder"
            ></v-text-field>
          </div>
          <div class="col-xs-12 form__input--name col-sm-2">
            <p class="form__input--label">{{ formfield.middle_initial.title }}</p>
            <v-text-field
              v-model.trim="$v.formnew.middle_initial.$model"
              class="form-control"
              :class="[formfield.middle_initial.class, status($v.formnew.middle_initial)]"
              :error-messages="middleInitialErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.middle_initial"
              @input="$v.formnew.middle_initial.$touch()"
              @blur="$v.formnew.middle_initial.$touch()"
              @keyup.enter="submitData"
              :placeholder="formfield.middle_initial.placeholder"
            ></v-text-field>
          </div>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.position.title }}</p>
          <v-text-field
            v-model.trim="$v.formnew.position.$model"
            class="form-control"
            :error-messages="positionErrors"
            :class="[formfield.position.class, status($v.formnew.position)]"
            required
            outlined
            autocomplete="off"
            :maxlength="maxlen.position"
            @input="$v.formnew.position.$touch()"
            @blur="$v.formnew.position.$touch()"
            @keyup.enter="submitData"
            :placeholder="formfield.position.placeholder"
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.email.title }}</p>
          <v-text-field
            v-model.trim="$v.formnew.email.$model"
            class="form-control"
            :error-messages="emailErrors"
            :class="[formfield.email.class, status($v.formnew.email)]"
            required
            outlined
            autocomplete="off"
            :maxlength="maxlen.email"
            @input="$v.formnew.email.$touch()"
            @blur="$v.formnew.email.$touch()"
            @keypress="resetEmailStatus"
            @keyup.enter="submitData"
            :placeholder="formfield.email.placeholder"
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.mobile.title }}</p>
          <v-text-field
            v-model.trim="$v.formnew.mobile.$model"
            class="form-control"
            :error-messages="mobileNumberErrors"
            :class="[formfield.mobile.class, status($v.formnew.mobile)]"
            required
            outlined
            autocomplete="off"
            :maxlength="maxlen.mobile"
            @input="$v.formnew.mobile.$touch()"
            @blur="$v.formnew.mobile.$touch()"
            @keyup.enter="submitData"
            :placeholder="formfield.mobile.placeholder"
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.target_start_date.title }}</p>
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model.trim="$v.formnew.target_start_date.$model"
                :class="[
                  'form__input--calendar',
                  formfield.target_start_date.class,
                  status($v.formnew.target_start_date),
                ]"
                :error-messages="tstartDateErrors"
                append-icon="event"
                clearable
                required
                outlined
                readonly
                v-on="on"
                :placeholder="formfield.target_start_date.placeholder"
                @click:clear="$v.formnew.target_start_date.$model = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model.trim="$v.formnew.target_start_date.$model"
              :min="nowDate"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="form__btn-div">
          <button type="button" class="form__btn-link" @click="resetData">reset</button>
          <button
            type="button"
            class="form__btn btn__blue"
            v-bind:disabled="$v.formnew.$invalid || formsubmit ? true : false"
            @click="submitData"
          >
            {{ formsubmittxt }}
          </button>
        </div>
      </div>
    </v-form>
    <AdminDialog></AdminDialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuelidate from "vuelidate";
import moment from "moment";
import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";
import AdminDialog from "./Dialog/Dialog.vue";
import { isMobilePH } from "../../validators";

Vue.use(Vuelidate);

const max = {
  first_name: 100,
  last_name: 100,
  middle_initial: 5,
  position: 100,
  email: 100,
  mobile: 16,
  target_start_date: 100,
};

export default {
  name: "FormNew",
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10), // should get from server
      menu1: false,
      menu2: false,
      isUniqueEmail: "",
      emailExistArr: [],
      availableDates: [],
      formnew: {
        first_name: "",
        last_name: "",
        middle_initial: "",
        position: "",
        email: "",
        mobile: "",
        target_start_date: "",
        id: "",
      },
      tstartDateRules: [(v) => !!v || "This field is required."],
      maxlen: {
        first_name: max.first_name,
        last_name: max.last_name,
        middile_initial: max.middle_initial,
        position: max.position,
        email: max.email,
        mobile: max.mobile,
        target_start_date: max.target_start_date,
      },
      formsubmit: false,
      formsubmittxt: "Add Employee",

      // MODAL SUCCESS txt
      successupdate: {
        status: true,
        title: "Success",
        msg: "You have successfully added an employee.",
        button: "GO BACK TO DASHBOARD",
        link: "admin-home",
        params: "",
        close: "admin-home",
        success: true,
      },
      failedupdate: {
        status: true,
        title: "Oops!",
        msg: "Something went wrong. Please try again.",
        button: "GO BACK",
        link: "",
        params: "",
        close: "",
        success: false,
      },
    };
  },
  computed: {
    ...mapGetters(["formfield", "adminsubmitemployee", "adminIsLogin"]),
    firstNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.first_name} characters long.`;
      if (!this.$v.formnew.first_name.$dirty) return errors;
      !this.$v.formnew.first_name.maxLength && errors.push(errtxt);
      !this.$v.formnew.first_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.last_name} characters long.`;
      if (!this.$v.formnew.last_name.$dirty) return errors;
      !this.$v.formnew.last_name.maxLength && errors.push(errtxt);
      !this.$v.formnew.last_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    middleInitialErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.middle_initial} characters long.`;
      if (!this.$v.formnew.middle_initial.$dirty) return errors;
      !this.$v.formnew.middle_initial.maxLength && errors.push(errtxt);
      return errors;
    },
    positionErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.position} characters long.`;
      if (!this.$v.formnew.position.$dirty) return errors;
      !this.$v.formnew.position.maxLength && errors.push(errtxt);
      !this.$v.formnew.position.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.email} characters long.`;
      if (!this.$v.formnew.email.$dirty) return errors;
      !this.$v.formnew.email.maxLength && errors.push(errtxt);
      !this.$v.formnew.email.email && errors.push("Invalid input. Try again.");
      !this.$v.formnew.email.required && errors.push("This field is required. Please try again.");
      !this.$v.formnew.email.isUnique && errors.push("Email already exist!");
      return errors;
    },
    mobileNumberErrors() {
      const errors = [];
      if (!this.$v.formnew.mobile.$dirty) return errors;
      !this.$v.formnew.mobile.required && errors.push("This field is required. Please try again.");
      return errors;
    },
    tstartDateErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.target_start_date} characters long.`;
      if (!this.$v.formnew.target_start_date.$dirty) return errors;
      !this.$v.formnew.target_start_date.maxLength && errors.push(errtxt);
      !this.$v.formnew.target_start_date.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
  },
  methods: {
    status(validation) {
      // vuelidator
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
    resetData() {
      // console.log()
      // Object.assign(this.$data, this.$options.data());
      this.$refs.form.reset();
      this.$v.$reset();
    },
    allowedDates(a) {
      // date picker
      return this.availableDates.includes(a);
    },
    submitData() {
      if (this.adminIsLogin) {
        // console.log(this.adminIsLogin);
        this.$v.$touch(); // it will validate all fields

        if (!this.$v.$invalid) {
          // all fields are valid
          // format date (timestamp)
          this.$store.dispatch("loadingTrue");
          // const formatDate = this.formnew.target_start_date ? moment(this.formnew.target_start_date).format() : '';

          const request = {
            first_name: this.formnew.first_name,
            last_name: this.formnew.last_name,
            position: this.formnew.position,
            email: this.formnew.email,
            mobile: this.formnew.mobile,
            target_start_date: `${this.formnew.target_start_date}`,
          };

          if (this.formnew.middle_initial) {
            request.middle_initial = this.formnew.middle_initial;
          }

          axios.post(this.adminsubmitemployee, request).then(
            (res) => {
              // console.log(res.data);
              if (res.data.success) {
                // show success
                this.$store.dispatch("setModal", this.successupdate);
              } else {
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);
              }
            },
            (error) => {
              // console.log(error.response);
              if (error.response && error.response.status === 409) {
                // email exist
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);

                // if email exist
                this.isUniqueEmail = false;
              } else if (error.response && error.response.status === 403) {
                this.$store.dispatch("resetUserAuth");
                this.$store.dispatch("resetUserInfo");
                this.$store.dispatch("resetUserProfile");
                this.$router.push({ name: "admin-login-forbidden" });
              } else {
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);
              }
            }
          );
        }
      } else {
        this.routeToPage("admin-login");
      }
    },
    resetEmailStatus() {
      if (this.isUniqueEmail === false) {
        this.isUniqueEmail = "";
      }
    },
  },
  validations: {
    formnew: {
      first_name: {
        required,
        maxLength: maxLength(max.first_name),
      },
      last_name: {
        required,
        maxLength: maxLength(max.last_name),
      },
      middle_initial: {
        maxLength: maxLength(max.middle_initial),
      },
      position: {
        required,
        maxLength: maxLength(max.position),
      },
      email: {
        email,
        maxLength: maxLength(max.email),
        required,
        // isUnique,
        isUnique() {
          if (this.isUniqueEmail === false) {
            return false;
          }

          return true;
        },
      },
      mobile: {
        required,
      },
      target_start_date: {
        required,
        maxLength: maxLength(max.mobile),
      },
    },
  },
  components: {
    AdminDialog,
  },
};
</script>
