<template>
  <div class="glogin">
    <div class="glogin__inner">
      <div class="glogin__body">
        <img :src=terilogo alt="teri logo" class="glogin__logo">
        <p class="text-center mt-8 glogin__title">Talent Entry &<br> Recruitment Intelligence</p>
        <LoginBtn></LoginBtn>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginBtn from '@/components/Admin/GoogleLogin.vue';

export default {
  name: 'Home',
  data() {
    return {
      terilogo: '/img/logo/teri.svg',
    };
  },
  components: {
    LoginBtn,
  },
};
</script>
