const data = {
  modal: {
    status: false,
    title: '',
    msg: '',
    button: '',
    link: '',
    params: '',
    close: '',
    success: '',
  },
};

export const getters = {
  modal: (state) => state.modal,
};

export const mutations = {
  setModal: (state, payload) => {
    state.modal.status = payload.status;
    state.modal.title = payload.title;
    state.modal.msg = payload.msg;
    state.modal.button = payload.button;
    state.modal.link = payload.link;
    state.modal.params = payload.params;
    state.modal.close = payload.close;
    state.modal.success = payload.success;
  },
  resetModal: (state) => {
    state.modal.status = false;
    state.modal.title = '';
    state.modal.msg = '';
    state.modal.button = '';
    state.modal.link = '';
    state.modal.params = '';
    state.modal.close = '';
    state.modal.success = '';
  },
};

export const actions = {
  setModal({ commit, dispatch }, payload) {
    commit('setModal', payload);
    // hide loading
    dispatch('loadingFalse');
  },
  resetModal({ commit, dispatch }) {
    commit('resetModal');
    // hide loading
    dispatch('loadingFalse');
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
