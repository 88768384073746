<template>
  <div class="new-emp row">
    <div class="container-left col-xs-12 col-md-9">
      <AdminHeader></AdminHeader>
      <div class="new-emp__body" v-if="profilestatus">
        <p class="mt-7 mb-4 admin__title admin__title--blue">Edit Employee Details</p>
        <FormUpdateUser :formupdate="user"></FormUpdateUser>
      </div>
      <div v-if="!profilestatus && !loadingPage" class="admin-notfound d-flex align-center justify-center flex-column">
        <p class="my-12 notfound--txt">{{profilefailedtxt}}</p>
        <router-link :to="{name:'admin-home'}" class="btn__blue">Back to Home</router-link>
      </div>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
// @ is an alias to /src
import FormUpdateUser from '@/components/Admin/FormUpdateUser.vue';
import AdminHeader from '@/components/Admin/Header.vue';
import Sidebar from '@/components/Admin/Sidebar.vue';
import moment from 'moment';

export default {
  name: 'AdminUpdateUser',
  data() {
    return {
      profilestatus: false,
      profilefailedtxt: 'Employee Not Found',
      loading: true,
      user: {
        user_id: '',
        first_name: '',
        last_name: '',
        middle_initial: '',
        email: '',
        mobile: '',
        position: '',
        target_start_date: '',
        progress: '',
      },
    };
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    ...mapGetters([
      'getprofileapi',
      'loadingPage',
    ]),
  },
  methods: {
    getProfile() {
      this.$store.dispatch('loadingTrue');
      const request = {
        user_id: this.$route.params.id.toString(),
      };
      axios.post(this.getprofileapi, request).then(
        (res) => {
          // console.log(res.data.body.result.user.user_id);
          if (res.data.success) {
            if (res.data.result.user) {
              this.setUserProfile(res.data.result.user);
              this.profilestatus = true;
              this.$store.dispatch('loadingFalse');
            }
          } else {
            this.userNotFound();
          }
        }, (error) => {
          // console.log(error);
          this.showErrorPage();
        },
      );
    },
    setUserProfile(userresp) {
      this.user = userresp;
      // console.log(userresp.target_start_date);
      this.formatUpdateFields();
    },
    formatUpdateFields() {
      this.user.mobile = this.user.mobile.split('+63').pop();
      const format = moment(this.user.target_start_date).format('YYYY-MM-DD');
      this.user.target_start_date = format;
    },
    userNotFound() {
      this.profilefailedtxt = 'Employee Not Found.';
      this.profilestatus = false;
      this.$store.dispatch('loadingFalse');
    },
    showErrorPage() {
      this.profilefailedtxt = 'Oops! Something Went Wrong';
      this.profilestatus = false;
      this.$store.dispatch('loadingFalse');
    },
  },
  components: {
    FormUpdateUser,
    AdminHeader,
    Sidebar,
  },
};
</script>
