export default function auth({ next }) {
  // if (!store.getters.userAuth.access_token) {
  // console.log('localStorage.getItem(access_token)', localStorage.getItem('access_token'));
  if (!localStorage.getItem('access_token')) {
    return next({
      name: 'user-login',
    });
  }

  return next();
}
