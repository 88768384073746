<template>
  <div class="form">
    <v-form ref="form">
      <div class="form__div">
        <div class="d-flex flex-wrap">
          <div class="col-xs-12 form__input--name col-sm-5">
            <p class="form__input--label">{{ formfield.first_name.title }}</p>
            <v-text-field
              v-model.trim="$v.formupdate.first_name.$model"
              class="form-control"
              :class="[formfield.first_name.class, status($v.formupdate.first_name)]"
              :error-messages="firstNameErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.first_name"
              @input="$v.formupdate.first_name.$touch()"
              @blur="$v.formupdate.first_name.$touch()"
              @keyup="checkForm"
              :placeholder="formfield.first_name.placeholder"
            ></v-text-field>
          </div>
          <div class="col-xs-12 form__input--name col-sm-5">
            <p class="form__input--label">{{ formfield.last_name.title }}</p>
            <v-text-field
              v-model.trim="$v.formupdate.last_name.$model"
              class="form-control"
              :class="[formfield.last_name.class, status($v.formupdate.last_name)]"
              :error-messages="lastNameErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.last_name"
              @input="$v.formupdate.last_name.$touch()"
              @blur="$v.formupdate.last_name.$touch()"
              @keyup="checkForm"
              :placeholder="formfield.last_name.placeholder"
            ></v-text-field>
          </div>
          <div class="col-xs-12 form__input--name col-sm-2">
            <p class="form__input--label">{{ formfield.middle_initial.title }}</p>
            <v-text-field
              v-model.trim="$v.formupdate.middle_initial.$model"
              class="form-control"
              :class="[formfield.middle_initial.class, status($v.formupdate.middle_initial)]"
              :error-messages="middleInitialErrors"
              required
              outlined
              autocomplete="off"
              :maxlength="maxlen.middle_initial"
              @input="$v.formupdate.middle_initial.$touch()"
              @blur="$v.formupdate.middle_initial.$touch()"
              @keyup="checkForm"
              :placeholder="formfield.middle_initial.placeholder"
            ></v-text-field>
          </div>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.position.title }}</p>
          <v-text-field
            v-model.trim="$v.formupdate.position.$model"
            class="form-control"
            :error-messages="positionErrors"
            :class="[formfield.position.class, status($v.formupdate.position)]"
            required
            outlined
            autocomplete="off"
            :maxlength="maxlen.position"
            @input="$v.formupdate.position.$touch()"
            @blur="$v.formupdate.position.$touch()"
            @keyup="checkForm"
            :placeholder="formfield.position.placeholder"
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.email.title }}</p>
          <v-text-field
            v-model.trim="this.formupdate.email"
            class="form-control"
            :class="[formfield.email.class]"
            required
            outlined
            autocomplete="off"
            disabled
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.mobile.title }}</p>
          <v-text-field
            v-model.trim="this.formupdate.mobile"
            class="form-control"
            :class="[formfield.mobile.class]"
            outlined
            disabled
            autocomplete="off"
          ></v-text-field>
        </div>
        <div class="form__row col-xs-12 col-sm-12">
          <p class="form__input--label">{{ formfield.target_start_date.title }}</p>
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model.trim="$v.formupdate.target_start_date.$model"
                :class="[
                  'form__input--calendar',
                  formfield.target_start_date.class,
                  status($v.formupdate.target_start_date),
                ]"
                :error-messages="tstartDateErrors"
                append-icon="event"
                ref="calendardate_update"
                clearable
                required
                outlined
                readonly
                v-on="on"
                :placeholder="formfield.target_start_date.placeholder"
                @click:clear="$v.formupdate.target_start_date.$model = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model.trim="$v.formupdate.target_start_date.$model"
              class="form__date-pick"
              @change="checkFormDate"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="form__btn-div">
          <!-- <button type="button" class="form__btn-link" @click="resetData">reset</button> -->
          <button
            type="button"
            class="form__btn btn__blue"
            v-bind:disabled="$v.formupdate.$invalid || formsubmit ? true : false"
            @click="submitData"
          >
            {{ formsubmittxt }}
          </button>
        </div>
      </div>
    </v-form>
    <AdminDialog></AdminDialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuelidate from "vuelidate";
import moment from "moment";
import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";
import AdminDialog from "./Dialog/Dialog.vue";
import { isMobilePH } from "../../validators";

Vue.use(Vuelidate);

const max = {
  first_name: 100,
  last_name: 100,
  middle_initial: 5,
  position: 100,
  email: 100,
  mobile: 16,
  target_start_date: 100,
};

export default {
  name: "FormUpdateUser",
  props: ["formupdate"],
  data() {
    return {
      loading: true,
      nowDate: new Date().toISOString().slice(0, 10),
      menu1: false,
      menu2: false,
      emailExistArr: [],
      availableDates: [],
      tstartDateRules: [(v) => !!v || "This field is required."],
      maxlen: {
        first_name: max.first_name,
        last_name: max.last_name,
        middile_initial: max.middle_initial,
        position: max.position,
        email: max.email,
        mobile: max.mobile,
        target_start_date: max.target_start_date,
      },
      formsubmit: true,
      formsubmittxt: "Update Employee",

      // MODAL SUCCESS txt
      successupdate: {
        status: true,
        title: "Success",
        msg: "You have updated the profile.",
        button: "GO BACK TO PROFILE",
        link: "admin-employee",
        params: this.$route.params.id.toString(),
        close: "admin-employee",
        success: true,
      },
      failedupdate: {
        status: true,
        title: "Oops!",
        msg: "Something went wrong. Please review the fields.",
        button: "GO BACK",
        link: "",
        params: "",
        close: "",
        success: false,
      },
    };
  },
  computed: {
    ...mapGetters(["formfield", "adminupdateemployee", "adminIsLogin", "updateprofileapi"]),
    formatCalendarTxt() {
      return this.date ? moment(this.date).format("MM/DD/YYYY") : "";
    },
    firstNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.first_name} characters long.`;
      if (!this.$v.formupdate.first_name.$dirty) return errors;
      !this.$v.formupdate.first_name.maxLength && errors.push(errtxt);
      !this.$v.formupdate.first_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.last_name} characters long.`;
      if (!this.$v.formupdate.last_name.$dirty) return errors;
      !this.$v.formupdate.last_name.maxLength && errors.push(errtxt);
      !this.$v.formupdate.last_name.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    middleInitialErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.middle_initial} characters long.`;
      if (!this.$v.formupdate.middle_initial.$dirty) return errors;
      !this.$v.formupdate.middle_initial.maxLength && errors.push(errtxt);
      return errors;
    },
    positionErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.position} characters long.`;
      if (!this.$v.formupdate.position.$dirty) return errors;
      !this.$v.formupdate.position.maxLength && errors.push(errtxt);
      !this.$v.formupdate.position.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.email} characters long.`;
      if (!this.$v.formupdate.email.$dirty) return errors;
      !this.$v.formupdate.email.maxLength && errors.push(errtxt);
      !this.$v.formupdate.email.email && errors.push("Invalid input. Try again.");
      !this.$v.formupdate.email.required &&
        errors.push("This field is required. Please try again.");
      !this.$v.formupdate.email.isUnique && errors.push("Email already exist!");
      return errors;
    },
    mobileNumberErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.mobile} characters long.`;
      if (!this.$v.formupdate.mobile.$dirty) return errors;
      !this.$v.formupdate.mobile.maxLength && errors.push(errtxt);
      !this.$v.formupdate.mobile.isMobilePH && errors.push("Invalid Format");
      !this.$v.formupdate.mobile.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
    tstartDateErrors() {
      const errors = [];
      const errtxt = `This field must be at most ${max.target_start_date} characters long.`;
      if (!this.$v.formupdate.target_start_date.$dirty) return errors;
      !this.$v.formupdate.target_start_date.maxLength && errors.push(errtxt);
      !this.$v.formupdate.target_start_date.required &&
        errors.push("This field is required. Please try again.");
      return errors;
    },
  },
  methods: {
    status(validation) {
      // vuelidator
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
    allowedDates(a) {
      // date picker
      return this.availableDates.includes(a);
    },
    checkForm() {
      this.formsubmit = this.$v.$invalid;
    },
    checkFormDate() {
      this.menu1 = false;
      this.formsubmit = this.$v.$invalid;
    },
    submitData() {
      if (this.adminIsLogin) {
        this.$v.$touch(); // it will validate all fields

        if (
          !this.$v.$invalid &&
          this.$route.params.id.toString() === this.formupdate.user_id.toString()
        ) {
          // all fields are valid
          // format date (timestamp)
          this.$store.dispatch("loadingTrue");

          const request = {
            id: this.formupdate.user_id,
            first_name: this.formupdate.first_name,
            last_name: this.formupdate.last_name,
            position: this.formupdate.position,
            email: this.formupdate.email,
            mobile: this.formupdate.mobile,
            target_start_date: `${this.formupdate.target_start_date}`,
          };

          if (this.formupdate.middle_initial) {
            request.middle_initial = this.formupdate.middle_initial;
          }

          axios.post(this.updateprofileapi, request).then(
            (res) => {
              // console.log(res.data);
              if (res.data.success) {
                // show success
                this.$store.dispatch("setModal", this.successupdate);
              } else {
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);
              }
            },
            (error) => {
              // console.log(error.response);
              if (error.response && error.response.status === 409) {
                // email exist
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);
              } else if (error.response && error.response.status === 403) {
                this.$store.dispatch("resetUserAuth");
                this.$store.dispatch("resetUserInfo");
                this.$store.dispatch("resetUserProfile");
                this.$router.push({ name: "admin-login-forbidden" });
              } else {
                // show failed
                this.$store.dispatch("setModal", this.failedupdate);
              }
            }
          );
        }
      } else {
        this.routeToPage("admin-login");
      }
    },
  },
  validations: {
    formupdate: {
      first_name: {
        required,
        maxLength: maxLength(max.first_name),
      },
      last_name: {
        required,
        maxLength: maxLength(max.last_name),
      },
      middle_initial: {
        maxLength: maxLength(max.middle_initial),
      },
      position: {
        required,
        maxLength: maxLength(max.position),
      },
      // email: {
      //   email,
      //   maxLength: maxLength(max.email),
      //   required,
      //   // isUnique,
      //   isUnique() {
      //     if (this.isUniqueEmail === false) {
      //       return false;
      //     }

      //     return true;
      //   },
      // },
      // mobile: {
      //   required,
      //   maxLength: maxLength(max.mobile),
      //   isMobilePH,
      // },
      target_start_date: {
        required,
        maxLength: maxLength(max.mobile),
      },
    },
  },
  components: {
    AdminDialog,
  },
};
</script>
