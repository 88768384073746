import axios from "axios";
import router from "../../router";

const data = {
  userprofile: {},
  profilestatus: false,
};

export const getters = {
  userprofile: (state) => state.userprofile,
  profilestatus: (state) => state.profilestatus,
};

export const mutations = {
  setUserProfile: (state, payload) => {
    state.userprofile = payload;
  },
  resetUserProfile: (state) => {
    state.userprofile = {};
    state.profilestatus = false;
  },
  setProfileStatus: (state, payload) => {
    state.profilestatus = payload;
  },
};

export const actions = {
  setUserProfile({ commit }, payload) {
    commit("setUserProfile", payload);
  },
  setProfileStatus({ commit }, payload) {
    commit("setProfileStatus", payload);
  },
  resetUserProfile({ commit }) {
    commit("resetUserProfile");
  },
  async callUserProfileApi({ dispatch, rootState }, payload) {
    dispatch("loadingTrue");
    dispatch("resetUserProfile");

    // actual submission of data
    try {
      const res = await axios.post(rootState.services.getprofileapi, payload);
      // console.log(res.data.body.result.user.user_id);
      if (res.data.success) {
        if (res.data.result.user) {
          dispatch("setUserProfile", res.data.result.user);
          dispatch("setProfileStatus", true);
        } else {
          dispatch("setProfileStatus", false);
        }
      } else {
        dispatch("setProfileStatus", false);
      }
      dispatch("loadingFalse");
    } catch (error) {
      // console.log(error.response);
      if (error.response.status === 403) {
        const role = localStorage.getItem("user_role");
        dispatch("resetUserAuth");
        dispatch("resetUserInfo");
        dispatch("resetUserProfile");

        const name = role === "user" ? "user-login-forbidden" : "admin-login-forbidden";
        router.push({ name: name });
      }
      dispatch("setProfileStatus", false);
      dispatch("loadingFalse");
    }
  },
  getUserProfile({ dispatch }, payload) {
    // pass request and prepare to submit data
    return new Promise((resolve, reject) => {
      dispatch("callUserProfileApi", payload).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
