<template>
  <div class="admin-emp row">
    <div class="container-left col-xs-12 col-md-9">
      <AdminHeader></AdminHeader>
      <div v-if="profilestatus">
        <p class="admin__title">Employee Information Sheet</p>
        <EIS :eisval="eis"></EIS>
      </div>
      <div
        v-if="!profilestatus && !loadingPage"
        class="admin-notfound d-flex align-center justify-center flex-column"
      >
        <p class="my-12 notfound--txt">{{ profilefailedtxt }}</p>
        <router-link :to="{ name: 'admin-home' }" class="btn__blue">Back to Home</router-link>
      </div>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { mapGetters } from "vuex";

import AdminHeader from "../../components/Admin/Header.vue";
import Sidebar from "../../components/Admin/Sidebar.vue";
import EIS from "../../components/User/EIS/EIS.vue";

export default {
  name: "AdminViewEis",
  components: {
    AdminHeader,
    Sidebar,
    EIS,
  },
  mounted() {
    this.getProfile();
  },
  data() {
    return {
      profilestatus: false,
      profilefailedtxt: "Employee Not Found",
      user: {
        user_id: "",
        first_name: "",
        last_name: "",
        middle_initial: "",
        email: "",
        mobile: "",
        position: "",
        target_start_date: "",
        progress: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getprofileapi", "loadingPage", "eis"]),
  },
  methods: {
    getProfile() {
      this.$store.dispatch("loadingTrue");
      const request = {
        user_id: this.$route.params.id.toString(),
      };
      // console.log(request);
      axios.post(this.getprofileapi, request).then(
        (res) => {
          if (res.data.success) {
            if (res.data.result.user) {
              this.setUserProfile(res.data.result.user);
              this.profilestatus = true;
              this.getRequirements();
            }
          } else {
            this.userNotFound();
          }
        },
        (error) => {
          // TODO show error
          this.showErrorPage();
        }
      );
    },
    setUserProfile(userresp) {
      this.user = userresp;
    },
    userNotFound() {
      this.profilefailedtxt = "Employee Not Found.";
      this.profilestatus = false;
      this.$store.dispatch("loadingFalse");
    },
    showErrorPage() {
      this.profilefailedtxt = "Oops! Something Went Wrong";
      this.profilestatus = false;
      this.$store.dispatch("loadingFalse");
    },
    getRequirements() {
      // TODO check if admin is logged in
      const request = {
        user_id: this.$route.params.id.toString(),
      };
      this.$store.dispatch("getRequirements", request).then(() => {
        // set dependents
        if (this.eis.dependents) {
          this.$store.dispatch("setDependents", JSON.parse(this.eis.dependents.slice(1, -1)));
        }

        // set workplace
        if (this.eis.work_experience) {
          this.$store.dispatch(
            "setWorkExperience",
            JSON.parse(this.eis.work_experience.slice(1, -1))
          );
        }

        // set char ref
        if (this.eis.character_reference) {
          this.$store.dispatch(
            "setCharacterReference",
            JSON.parse(this.eis.character_reference.slice(1, -1))
          );
        }

        this.$store.dispatch("loadingFalse");
      });
    },
  },
};
</script>
