<template>
    <v-dialog v-model="modal.status" persistent no-click-animation>
        <button class="modal__btn--close" @click="goto(modal.close,modal.params)"><i class="material-icons">clear</i></button>
        <div class="modal__body tk-museo-sans-rounded" :class="{'modal__success': modal.success === true,'modal__failed': modal.success === false}">
            <p class="modal__title">{{ modal.title }}</p>
            <p class="modal__msg">{{ modal.msg }}</p>

            <div class="modal__btn" v-if="modal.link != 'close'">
              <button @click="goto(modal.link,modal.params)" class="form__btn btn__blue">
                {{ modal.button }}
              </button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /* eslint-disable */
  name: 'AdminDialog',
  computed: {
    ...mapGetters([
      'modal',
    ]),
  },
  methods:{
    goto(des,params='') {
      this.$store.dispatch('resetModal');
      if (des === this.$router.currentRoute.name) {
        window.location.href = this.$router.currentRoute.fullPath;
      } else {
        if(des) { //if link name has destination
          if(params){
            this.$router.push({
              name: des,
              params: { id: params },
            });
          } else{
            this.$router.push({
              name: des,
            });
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">

</style>