<template>
  <v-app>
    <Loading></Loading>
    <div class="app-container tk-museo-sans-rounded" :class="!loadingPage ? 'bg-blue' : ''">
      <router-view/>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from './views/Loading.vue';

export default {
  name: 'App',
  data: () => ({
  }),
  computed: {
    ...mapGetters([
      'loadingPage',
    ]),
  },
  components: {
    Loading,
  },
};
</script>

<style lang="scss">
@import './assets/scss/style.scss';
</style>
