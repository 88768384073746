<template>
  <div class="static static__error">
    <div class="static__body">
      <h1 class="static__title">403</h1>
      <p class="static__msg">Not Allowed</p>
      <router-link :to="{name:'user-home'}" class="static__btn">Go to Home</router-link>
    </div>
    <div class="static__footer">
      <img :src="terifooter" alt="teri footer logo">
      <p class="mb-0 ml-6">Talent Entry & Recruitment Intelligence</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AdminLoginForbidden',
  data() {
    return {
      terifooter: '/img/logo/teri-footer-error.svg',
    };
  },
  mounted() {
    this.$store.dispatch('resetAdminState');
    this.$store.dispatch('loadingFalse');
  },
};
</script>
