const applocalstorage = (function setupLocalStorage() {
  let appservice;
  function getAppService() {
    if (!appservice) {
      appservice = this;
      return appservice;
    }
    return appservice;
  }
  function setAppToken(tokenObj) {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }
  function getAuthToken() {
    return localStorage.getItem('access_token');
  }
  function getRefToken() {
    return localStorage.getItem('refresh_token');
  }
  function resetToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
  function setUserAuthInfo(tokenObj) {
    localStorage.setItem('user_id', tokenObj.user_id);
    localStorage.setItem('user_role', tokenObj.user_role);
  }
  function getUserInfoId() {
    return localStorage.getItem('user_id');
  }
  function getUserInfoRole() {
    return localStorage.getItem('user_role');
  }
  function resetUserInfo() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role');
  }
  function setAdminInfoRole(tokenObj) {
    localStorage.setItem('admin_role', tokenObj);
  }
  function getAdminInfoRole() {
    return localStorage.getItem('admin_role');
  }
  function resetAdminRoleInfo() {
    localStorage.removeItem('admin_role');
  }
  return {
    getService: getAppService,
    setToken: setAppToken,
    getAccessToken: getAuthToken,
    getRefreshToken: getRefToken,
    setUserInfo: setUserAuthInfo,
    getUserId: getUserInfoId,
    getUserRole: getUserInfoRole,
    clearToken: resetToken,
    clearUserInfo: resetUserInfo,
    getAdminRole: getAdminInfoRole,
    setAdminRole: setAdminInfoRole,
    resetAdminRole: resetAdminRoleInfo,
  };
}());
export default applocalstorage;
