<template>
  <div class="admin-onboard">
    <h1 class="admin__title">Onboarding list</h1>
    <div class="onboard-list" v-if="employees">
      <div
        class="onboard-list__items row"
        v-for="item in onboardlist"
        :key="item.id"
        @click="gotoEmployee(item.user_id)"
      >
        <div class="col-xs-12 col-md-3 onboard-list__txt onboard-list__name">
          <p class="ma-0">{{ item.first_name }} {{ item.last_name }}</p>
        </div>
        <div class="col-xs-12 col-md-3 onboard-list__txt onboard-list__position">
          <p class="ma-0 f-color--light-black">{{ item.position }}</p>
        </div>
        <div class="col-xs-12 col-md-2 onboard-list__txt onboard-list__date">
          <p class="ma-0 f-color--light-black">{{ item.target_start_date | momentFull }}</p>
        </div>
        <div class="col-xs-12 col-md-4 onboard-list__txt onboard-list__progress">
          <div class="progress-bar row">
            <v-progress-linear
              v-model="item.progress"
              color="#71C5F0"
              class="progress progress__inline"
              :class="{
                'progress__inline--20': item.progress < 30,
                'progress__inline--50': item.progress > 30 && item.progress < 60,
                'progress__inline--100': item.progress > 60,
              }"
            ></v-progress-linear>
            <p class="ma-0 progress__txt f-color--light-black">{{ item.progress }}%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="onboard-list-empty" v-if="!employees">
      <p class="onboard-empty">{{ onboardtxtfailed }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "OnboardList",
  data() {
    return {
      // image
      adminname: "Rase",
      hravatarboy: "/img/icons/web/hr-avatar-boy.svg",
      valueDeterminate: 50,
      onboardlist: [],
      employees: true,
      onboardtxtfailed: "Employees not found",
    };
  },
  methods: {
    getUsers() {
      this.$store.dispatch("loadingTrue");
      axios.get(this.getusersapi).then(
        (res) => {
          if (res.data.success) {
            // show success
            this.onboardlist = res.data.results.users;
            if (Object.keys(this.onboardlist).length === 0) {
              this.employees = false;
              this.onboardtxtfailed = "Employees not Found.";
            }
            this.$store.dispatch("loadingFalse");
          } else {
            // TODO show failed ?
            this.goToFailed();
          }
        },
        (error) => {
          // TODO show error
          // console.log(error.response);
          if (error.response.status === 403) {
            this.$store.dispatch("resetUserAuth");
            this.$store.dispatch("resetUserInfo");
            this.$store.dispatch("resetUserProfile");
            this.$router.push({ name: "admin-login-forbidden" });
          } else if (error.response && error.response.status === 400) {
            this.employees = false;
            this.onboardtxtfailed = "Employees not Found.";
            this.$store.dispatch("loadingFalse");
          } else {
            this.goToFailed();
          }
        }
      );
    },
    gotoEmployee(empId) {
      this.$router.push({
        name: "admin-employee",
        params: { id: empId },
      });
    },
    goToFailed() {
      this.employees = false;
      this.onboardtxtfailed = "Something Went Wrong.";
      this.$store.dispatch("loadingFalse");
    },
  },
  computed: {
    ...mapGetters(["getusersapi"]),
  },
  components: {},
  mounted() {
    this.getUsers();
  },
  filters: {
    momentFull(date) {
      return moment(date).format("MMMM DD, YYYY");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
