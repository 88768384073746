import Vue from "vue";
import VueRouter from "vue-router";
import VueScrollTo from "vue-scrollto";
// eslint-disable-next-line import/no-cycle
import store from "../store";

// ADMIN
import AdminHome from "../views/Admin/AdminHome.vue";
import AdminLogin from "../views/Admin/AdminLogin.vue";
import AdminAddUser from "../views/Admin/AdminAddUser.vue";
import AdminViewUser from "../views/Admin/AdminViewUser.vue";
import AdminUpdateUser from "../views/Admin/AdminUpdateUser.vue";
import AdminViewEis from "../views/Admin/AdminViewEis.vue";

// ADMIN ERROR PAGES
import AdminLoginForbidden from "../views/Admin/Error/403.vue";
import AdminLoginFailed from "../views/Admin/Error/500.vue";
import AdminNotFound from "../views/Admin/Error/404.vue";

// MIDDLEWARE ADMIN
import guestadmin from "./middleware/guestadmin";
import admin from "./middleware/admin";
import middlewarePipeline from "./middlewarePipeline";
import roleadmin from "./middleware/roleadmin";

// MIDDLEWARE USER
import auth from "./middleware/auth";
import guestuser from "./middleware/guestuser";

// USER
import UserLogin from "../views/User/UserLogin.vue";
import UserHome from "../views/User/UserHome.vue";
import UserRequirements from "../views/User/UserRequirements.vue";

// USER ERROR PAGES
import UserLoginForbidden from "../views/User/Error/403.vue";
import UserLoginFailed from "../views/User/Error/500.vue";
import UserNotFound from "../views/User/Error/404.vue";

Vue.use(VueRouter);

const routes = [
  // ADMIN ROUTES
  {
    path: "/admin/home",
    name: "admin-home",
    component: AdminHome,
    meta: {
      middleware: [
        admin
        //roleadmin
      ]
    }
  },
  {
    path: "/admin",
    name: "admin-login",
    component: AdminLogin,
    meta: {
      middleware: [guestadmin]
    }
  },
  {
    path: "/admin/employee/add",
    name: "admin-create-new",
    component: AdminAddUser,
    meta: {
      middleware: [admin]
    }
  },
  {
    path: "/admin/employee/view/:id",
    name: "admin-employee",
    component: AdminViewUser,
    meta: {
      middleware: [admin]
    }
  },
  {
    path: "/admin/employee/view/eis/:id",
    name: "admin-employee-eis",
    component: AdminViewEis,
    meta: {
      middleware: [admin]
    }
  },
  {
    path: "/admin/employee/update/:id",
    name: "admin-update-employee",
    component: AdminUpdateUser,
    meta: {
      middleware: [admin]
    }
  },
  // ADMIN ERROR ROUTES
  {
    path: "/admin/login-forbidden",
    name: "admin-login-forbidden",
    component: AdminLoginForbidden
  },
  {
    path: "/admin/failed",
    name: "admin-failed",
    component: AdminLoginFailed
  },
  {
    path: "/admin/not-found",
    name: "admin-not-found",
    component: AdminNotFound
  },

  // USER ROUTES
  {
    path: "/",
    name: "user-login",
    component: UserLogin,
    meta: {
      middleware: [guestuser]
    }
  },
  {
    path: "/home",
    name: "user-home",
    component: UserHome,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/requirements",
    name: "user-requirements",
    component: UserRequirements,
    meta: {
      middleware: [auth]
    }
  },

  // USER ERROR ROUTES
  {
    path: "/login-forbidden",
    name: "user-login-forbidden",
    component: UserLoginForbidden
  },
  {
    path: "/failed",
    name: "user-failed",
    component: UserLoginFailed
  },
  {
    path: "/not-found",
    name: "user-not-found",
    component: UserNotFound
  },
  {
    path: "*",
    name: "page-not-found",
    component: UserNotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: to => {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, {
        container: "body",
        duration: 1000,
        easing: "ease",
        offset: -10
      });

      return {
        selector: to.hash,
        offset: { x: 0, y: 0 }
      };
    }
    return { x: 0, y: 0 };
  },
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;

  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
