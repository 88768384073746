export default function admin({ next, store }) {
  store.dispatch('googleSignIn').then(() => {
    if (!store.getters.adminIsLogin) {
      return next({
        name: 'admin-login',
      });
    }
    return next();
  });
}
