// INPUT FIELDS TO BE DISPLAYED AND LOOP

const data = {
  // CREATE NEW EMPLOYEE
  formfield: {
    first_name: {
      title: "First Name",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "First name",
    },
    last_name: {
      title: "Last Name",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "Last name",
    },
    middle_initial: {
      title: "Middle Initial",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "M. I.",
    },
    position: {
      title: "Designation / Role",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "Position",
    },
    email: {
      title: "Email Address",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "name@email.com",
    },
    mobile: {
      title: "Mobile Number",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "",
    },
    target_start_date: {
      title: "Target Start Date",
      subtitle: "",
      class: "form__input form__input--text form__input--text-name form__input--sm",
      placeholder: "mm/dd/yy",
    },
  },
  nofileupload: ["EIS"],
  readhavestatus: ["ACCEPT"],
  formrules: {
    filesize: 5242880, // 5mb
    first_name: 100,
    last_name: 100,
    middle_name: 5,
    nickname: 100,
    age: 2,
    mailing_address: 100,
    // GOVERNMENT
    tin: 15, // including dash
    pagibig: 15, // including dash
    sss: 12, // including dash
    philhealth: 12,

    // EMERGENCY
    emergency_contact_name: 100,
    emergency_contact_relationship: 100,
    emergency_contact_number: 16,
    emergency_contact_address: 100,

    // DEPENDENTS
    dep_name: 100,
    dep_age: 2,

    // WORK EXPERIENCE
    company_name: 100,
    last_position_held: 100,
    immediate_supervisor: 100,
    reason_for_leaving: 100,

    // NEW EMPLOYEE
    position: 100,
    email: 100,
    mobile: 16,
    target_start_date: 100,
    acceptfiles: [".doc", ".docx", ".pdf", "image/*"],
  },
  eisfield: {
    class: "form-control form__input form__input--text form__input--text-name form__input--sm",
    personal: {
      last_name: {
        title: "Last Name",
        subtitle: "",
        model: "eis.last_name",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Last name",
      },
      first_name: {
        title: "First Name",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "First name",
      },
      middle_name: {
        title: "Middle Initial",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "M. I.",
      },
      suffix: {
        title: "Suffix",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Suffix",
      },
      nickname: {
        title: "Nickname",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Nickname",
      },
      gender: {
        title: "Gender",
        subtitle: "",
        type: "select",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Gender",
        list: [
          { value: "Male", text: "Male" },
          { value: "Female", text: "Female" },
        ],
      },
      birthday: {
        title: "Birthdate",
        subtitle: "",
        type: "datepicker",
        columns: "col-xs-12 form__input  col-sm-4",
        class:
          "form-control form__input form__date form__input--text form__input--text-name form__input--sm",
        placeholder: "MM/DD/YY",
      },
      age: {
        title: "Age",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Age",
      },
      civil_status: {
        title: "Civil Status",
        subtitle: "",
        type: "select",
        columns: "col-xs-12 form__input col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Civil Status",
        list: [
          { value: "Married", text: "Married" },
          { value: "Widowed", text: "Widowed" },
          { value: "Separated", text: "Separated" },
          { value: "Divorced", text: "Divorced" },
          { value: "Single", text: "Single" },
        ],
      },
      country_of_birth: {
        title: "Country of Birth",
        subtitle: "",
        type: "select",
        columns: "col-xs-12 form__input col-sm-12",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Country",
        list: [
          { value: "Afghanistan", text: "Afghanistan" },
          { value: "Albania", text: "Albania" },
          { value: "Algeria", text: "Algeria" },
          { value: "Andorra", text: "Andorra" },
          { value: "Angola", text: "Angola" },
          { value: "Antigua and Barbuda", text: "Antigua and Barbuda" },
          { value: "Argentina", text: "Argentina" },
          { value: "Armenia", text: "Armenia" },
          { value: "Australia", text: "Australia" },
          { value: "Austria", text: "Austria" },
          { value: "Azerbaijan", text: "Azerbaijan" },
          { value: "Bahamas", text: "Bahamas" },
          { value: "Bahrain", text: "Bahrain" },
          { value: "Bangladesh", text: "Bangladesh" },
          { value: "Barbados", text: "Barbados" },
          { value: "Belarus", text: "Belarus" },
          { value: "Belgium", text: "Belgium" },
          { value: "Belize", text: "Belize" },
          { value: "Benin", text: "Benin" },
          { value: "Bhutan", text: "Bhutan" },
          { value: "Bolivia", text: "Bolivia" },
          { value: "Bosnia and Herzegovina", text: "Bosnia and Herzegovina" },
          { value: "Botswana", text: "Botswana" },
          { value: "Brazil", text: "Brazil" },
          { value: "Brunei", text: "Brunei" },
          { value: "Bulgaria", text: "Bulgaria" },
          { value: "Burkina Faso", text: "Burkina Faso" },
          { value: "Burundi", text: "Burundi" },
          { value: "Cote d Ivoire", text: "Cote d Ivoire" },
          { value: "Cabo Verde", text: "Cabo Verde" },
          { value: "Cambodia", text: "Cambodia" },
          { value: "Cameroon", text: "Cameroon" },
          { value: "Canada", text: "Canada" },
          { value: "Central African Republic", text: "Central African Republic" },
          { value: "Chad", text: "Chad" },
          { value: "Chile", text: "Chile" },
          { value: "China", text: "China" },
          { value: "Colombia", text: "Colombia" },
          { value: "Comoros", text: "Comoros" },
          { value: "Congo (Congo-Brazzaville)", text: "Congo (Congo-Brazzaville)" },
          { value: "Costa Rica", text: "Costa Rica" },
          { value: "Croatia", text: "Croatia" },
          { value: "Cuba", text: "Cuba" },
          { value: "Cyprus", text: "Cyprus" },
          { value: "Czechia (Czech Republic)", text: "Czechia (Czech Republic)" },
          { value: "Democratic Republic of the Congo", text: "Democratic Republic of the Congo" },
          { value: "Denmark", text: "Denmark" },
          { value: "Djibouti", text: "Djibouti" },
          { value: "Dominica", text: "Dominica" },
          { value: "Dominican Republic", text: "Dominican Republic" },
          { value: "Ecuador", text: "Ecuador" },
          { value: "Egypt", text: "Egypt" },
          { value: "El Salvador", text: "El Salvador" },
          { value: "Equatorial Guinea", text: "Equatorial Guinea" },
          { value: "Eritrea", text: "Eritrea" },
          { value: "Estonia", text: "Estonia" },
          { value: 'Eswatini (fmr. "Swaziland")', text: 'Eswatini (fmr. "Swaziland")' },
          { value: "Ethiopia", text: "Ethiopia" },
          { value: "Fiji", text: "Fiji" },
          { value: "Finland", text: "Finland" },
          { value: "France", text: "France" },
          { value: "Gabon", text: "Gabon" },
          { value: "Gambia", text: "Gambia" },
          { value: "Georgia", text: "Georgia" },
          { value: "Germany", text: "Germany" },
          { value: "Ghana", text: "Ghana" },
          { value: "Greece", text: "Greece" },
          { value: "Grenada", text: "Grenada" },
          { value: "Guatemala", text: "Guatemala" },
          { value: "Guinea", text: "Guinea" },
          { value: "Guinea-Bissau", text: "Guinea-Bissau" },
          { value: "Guyana", text: "Guyana" },
          { value: "Haiti", text: "Haiti" },
          { value: "Holy See", text: "Holy See" },
          { value: "Honduras", text: "Honduras" },
          { value: "Hungary", text: "Hungary" },
          { value: "Iceland", text: "Iceland" },
          { value: "India", text: "India" },
          { value: "Indonesia", text: "Indonesia" },
          { value: "Iran", text: "Iran" },
          { value: "Iraq", text: "Iraq" },
          { value: "Ireland", text: "Ireland" },
          { value: "Israel", text: "Israel" },
          { value: "Italy", text: "Italy" },
          { value: "Jamaica", text: "Jamaica" },
          { value: "Japan", text: "Japan" },
          { value: "Jordan", text: "Jordan" },
          { value: "Kazakhstan", text: "Kazakhstan" },
          { value: "Kenya", text: "Kenya" },
          { value: "Kiribati", text: "Kiribati" },
          { value: "Kuwait", text: "Kuwait" },
          { value: "Kyrgyzstan", text: "Kyrgyzstan" },
          { value: "Laos", text: "Laos" },
          { value: "Latvia", text: "Latvia" },
          { value: "Lebanon", text: "Lebanon" },
          { value: "Lesotho", text: "Lesotho" },
          { value: "Liberia", text: "Liberia" },
          { value: "Libya", text: "Libya" },
          { value: "Liechtenstein", text: "Liechtenstein" },
          { value: "Lithuania", text: "Lithuania" },
          { value: "Luxembourg", text: "Luxembourg" },
          { value: "Madagascar", text: "Madagascar" },
          { value: "Malawi", text: "Malawi" },
          { value: "Malaysia", text: "Malaysia" },
          { value: "Maldives", text: "Maldives" },
          { value: "Mali", text: "Mali" },
          { value: "Malta", text: "Malta" },
          { value: "Marshall Islands", text: "Marshall Islands" },
          { value: "Mauritania", text: "Mauritania" },
          { value: "Mauritius", text: "Mauritius" },
          { value: "Mexico", text: "Mexico" },
          { value: "Micronesia", text: "Micronesia" },
          { value: "Moldova", text: "Moldova" },
          { value: "Monaco", text: "Monaco" },
          { value: "Mongolia", text: "Mongolia" },
          { value: "Montenegro", text: "Montenegro" },
          { value: "Morocco", text: "Morocco" },
          { value: "Mozambique", text: "Mozambique" },
          { value: "Myanmar (formerly Burma)", text: "Myanmar (formerly Burma)" },
          { value: "Namibia", text: "Namibia" },
          { value: "Nauru", text: "Nauru" },
          { value: "Nepal", text: "Nepal" },
          { value: "Netherlands", text: "Netherlands" },
          { value: "New Zealand", text: "New Zealand" },
          { value: "Nicaragua", text: "Nicaragua" },
          { value: "Niger", text: "Niger" },
          { value: "Nigeria", text: "Nigeria" },
          { value: "North Korea", text: "North Korea" },
          { value: "North Macedonia", text: "North Macedonia" },
          { value: "Norway", text: "Norway" },
          { value: "Oman", text: "Oman" },
          { value: "Pakistan", text: "Pakistan" },
          { value: "Palau", text: "Palau" },
          { value: "Palestine State", text: "Palestine State" },
          { value: "Panama", text: "Panama" },
          { value: "Papua New Guinea", text: "Papua New Guinea" },
          { value: "Paraguay", text: "Paraguay" },
          { value: "Peru", text: "Peru" },
          { value: "Philippines", text: "Philippines" },
          { value: "Poland", text: "Poland" },
          { value: "Portugal", text: "Portugal" },
          { value: "Qatar", text: "Qatar" },
          { value: "Romania", text: "Romania" },
          { value: "Russia", text: "Russia" },
          { value: "Rwanda", text: "Rwanda" },
          { value: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis" },
          { value: "Saint Lucia", text: "Saint Lucia" },
          { value: "Saint Vincent and the Grenadines", text: "Saint Vincent and the Grenadines" },
          { value: "Samoa", text: "Samoa" },
          { value: "San Marino", text: "San Marino" },
          { value: "Sao Tome and Principe", text: "Sao Tome and Principe" },
          { value: "Saudi Arabia", text: "Saudi Arabia" },
          { value: "Senegal", text: "Senegal" },
          { value: "Serbia", text: "Serbia" },
          { value: "Seychelles", text: "Seychelles" },
          { value: "Sierra Leone", text: "Sierra Leone" },
          { value: "Singapore", text: "Singapore" },
          { value: "Slovakia", text: "Slovakia" },
          { value: "Slovenia", text: "Slovenia" },
          { value: "Solomon Islands", text: "Solomon Islands" },
          { value: "Somalia", text: "Somalia" },
          { value: "South Africa", text: "South Africa" },
          { value: "South Korea", text: "South Korea" },
          { value: "South Sudan", text: "South Sudan" },
          { value: "Spain", text: "Spain" },
          { value: "Sri Lanka", text: "Sri Lanka" },
          { value: "Sudan", text: "Sudan" },
          { value: "Suriname", text: "Suriname" },
          { value: "Sweden", text: "Sweden" },
          { value: "Switzerland", text: "Switzerland" },
          { value: "Syria", text: "Syria" },
          { value: "Tajikistan", text: "Tajikistan" },
          { value: "Tanzania", text: "Tanzania" },
          { value: "Thailand", text: "Thailand" },
          { value: "Timor-Leste", text: "Timor-Leste" },
          { value: "Togo", text: "Togo" },
          { value: "Tonga", text: "Tonga" },
          { value: "Trinidad and Tobago", text: "Trinidad and Tobago" },
          { value: "Tunisia", text: "Tunisia" },
          { value: "Turkey", text: "Turkey" },
          { value: "Turkmenistan", text: "Turkmenistan" },
          { value: "Tuvalu", text: "Tuvalu" },
          { value: "Uganda", text: "Uganda" },
          { value: "Ukraine", text: "Ukraine" },
          { value: "United Arab Emirates", text: "United Arab Emirates" },
          { value: "United Kingdom", text: "United Kingdom" },
          { value: "United States of America", text: "United States of America" },
          { value: "Uruguay", text: "Uruguay" },
          { value: "Uzbekistan", text: "Uzbekistan" },
          { value: "Vanuatu", text: "Vanuatu" },
          { value: "Venezuela", text: "Venezuela" },
          { value: "Vietnam", text: "Vietnam" },
          { value: "Yemen", text: "Yemen" },
          { value: "Zambia", text: "Zambia" },
          { value: "Zimbabwe", text: "Zimbabwe" },
        ],
      },
      mailing_address: {
        title: "Mailing Address",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input col-sm-12",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Building Number, Street Name, Neighborhood, City, Zip Code",
      },
    },
    government: {
      tin: {
        title: "TIN",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "xxx-xxx-xxx-xxx",
      },
      pagibig: {
        title: "Pag-ibig (HDMF)",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "xxx-xxx-xxx-xxx",
      },
      sss: {
        title: "SSS",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "xx-xxxxxxx-x",
      },
      philhealth: {
        title: "PhilHealth",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "xxxxxxxxxxxx",
      },
    },
    emergency: {
      emergency_contact_name: {
        title: "Name",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Full Name",
      },
      emergency_contact_relationship: {
        title: "Relationship",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      emergency_contact_number: {
        title: "Contact Number",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      emergency_contact_address: {
        title: "Address",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Building Number, Street Name, Neighborhood, City, Zip Code",
      },
    },
    dependents: {
      dep_name: {
        title: "Name",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Full Name",
      },
      dep_relationship: {
        title: "Relationship",
        subtitle: "",
        type: "select",
        columns: "col-xs-12 form__input  col-sm-3",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
        list: [
          { value: "Spouse", text: "Spouse" },
          { value: "Child", text: "Child" },
          { value: "Mother", text: "Mother" },
          { value: "Father", text: "Father" },
          { value: "Sibling", text: "Sibling" },
        ],
      },
      dep_birthdate: {
        title: "Birthdate",
        subtitle: "",
        type: "datepicker",
        columns: "col-xs-12 form__input  col-sm-4",
        class:
          "form-control form__input form__date form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      dep_age: {
        title: "Age",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-1",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
    },
    work_exp: {
      company_name: {
        title: "Company Name",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-12",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Company Name",
      },
      last_position_held: {
        title: "Last Position Held",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Last Position Held",
      },
      inclusive_start_date: {
        title: "Inclusive Start Date",
        subtitle: "",
        type: "datepicker",
        columns: "col-xs-12 form__input  col-sm-4",
        class:
          "form-control form__input form__date form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      inclusive_end_date: {
        title: "Inclusive End Date",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-4",
        class:
          "form-control form__input form__date form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      immediate_supervisor: {
        title: "Immediate Supervisor",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-12",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      reason_for_leaving: {
        title: "Reason for Leaving",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-12",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
    },
    character_reference: {
      name: {
        title: "Name",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "Full Name",
      },
      relationship: {
        title: "Relationship",
        subtitle: "",
        type: "select",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      company: {
        title: "Company",
        subtitle: "",
        type: "datepicker",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
      contact_number: {
        title: "Contact Number",
        subtitle: "",
        type: "text",
        columns: "col-xs-12 form__input  col-sm-6",
        class: "form-control form__input form__input--text form__input--text-name form__input--sm",
        placeholder: "",
      },
    },
  },
  eisfieldstatus: false,
};

export const getters = {
  formfield: (state) => state.formfield,
  nofileupload: (state) => state.nofileupload,
  readhavestatus: (state) => state.readhavestatus,
  formrules: (state) => state.formrules,
  eisfield: (state) => state.eisfield,
  eisfieldstatus: (state) => state.eisfieldstatus,
};

export const mutations = {
  eisFieldStatus: (state, payload) => {
    state.eisfieldstatus = payload;
  },
};
export const actions = {
  eisFieldStatus({ commit }, payload) {
    commit("eisFieldStatus", payload);
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
