<template>
  <div class="checklist">
    <div class="checklist__div">
      <div v-for="(item,i) in requirements" :key="i" :class="item.align" class="checklist__items" @click="gotoHash(item.file_key,item.user_req_id)"> <!--add active if anchored-->
        <v-checkbox :label="` ${item.requirement}`" :input-value="item.have_status || item.status === 1 || item.uploaded" disabled></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'UserBoardListBar',
  props: ['user'],
  data() {
    return {
      loading: true,
      currentpath: '',
    };
  },
  components: {
  },
  computed: {
    ...mapGetters([
      'requirements',
      'requirementstatus',
    ]),
  },
  mounted() {
    this.currentpath = this.$router.currentRoute.path;
  },
  methods: {
    gotoHash(hash, id) {
      let anchor = '';
      const checkkey = this.hideReq(hash);

      if (checkkey) {
        anchor = `#id_${hash}${id}`;
      } else {
        anchor = `#id_${hash}`;
      }

      window.location.href = this.currentpath + anchor;
    },
    hideReq(filekey) {
      if (filekey === 'EIS') {
        return false;
      }
      return true;
    },
  },
};
</script>
