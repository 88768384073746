<template>
  <div class="sidebar container-right col-xs-12 col-md-3">
    <div class="sidebar__first">
      <div class="calendar">
        <p class="calendar__date">{{datetoday | momentDate}}</p>
        <p class="calendar__day">{{datetoday | momentDay}}</p>
        <p class="calendar__monthyear">{{datetoday | momentMoYear}}</p>
      </div>
      <div class="calendar__names">

        <div class="calendar__first" v-if="calendarname.firstday.length != 0">
          <p class="calendar__title first">First day</p>
          <div v-for="item in calendarname.firstday" :key="item.id" class="calendar__link ml-4" @click="gotoEmployee(item.user_id)">
            <p class="ma-0 calendar__txt">{{ item.first_name}} {{ item.last_name}}</p>
          </div>
        </div>
        <div class="calendar__update" v-if="calendarname.update.length != 0">
          <p class="calendar__title update">Update</p>
          <div v-for="item in calendarname.update" :key="item.id" class="calendar__link ml-4" @click="gotoEmployee(item.user_id)">
            <p class="ma-0 calendar__txt">{{ item.first_name}} {{ item.last_name}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__second">
      <GoogleLogout></GoogleLogout>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import momenttz from 'moment-timezone';

import GoogleLogout from '@/components/Admin/GoogleLogout.vue';
// import api from '../../utils/api';

export default {
  name: 'SideBar',
  data() {
    return {
      datetoday: new Date(),
      // calendarname: '',
      calendarname: {
        firstday: [],
        update: [],
      },
    };
  },
  components: {
    GoogleLogout,
  },
  computed: {
    ...mapGetters([
      'getdatetodayapi',
      'getuserscalendarapi',
    ]),
  },
  mounted() {
    momenttz.tz.setDefault('Asia/Manila');
    this.getDateToday();
    this.getUsersUpdate();
  },
  methods: {
    getDateToday() {
      // api.getAuthData(this.getdatetodayapi).then(
      axios.get(this.getdatetodayapi).then(
        (res) => {
          if (res.data.success) {
            // show success
            this.datetoday = res.data.result.date;
          } else {
            this.showCurrentDate();
          }
        }, (error) => {
          // console.log(error);
          this.showCurrentDate();
        },
      ).catch(
        (error) => {
          // console.log('catch error: ');
          // console.log(error);
          this.showCurrentDate();
        },
      );
    },
    showCurrentDate() {
      // show current date
      this.datetoday = new Date();
    },
    getUsersUpdate() {
      axios.get(this.getuserscalendarapi).then(
        (res) => {
          this.calendarname.firstday = res.data.result.first_day;
          this.calendarname.update = res.data.result.update;
        }, (error) => {
          // console.log(error);
        },
      ).catch(
        (error) => {
          // console.log('catch error: ');
          // console.log(error);
        },
      ).finally(() => { this.loading = false; });
    },
    gotoEmployee(empId) {
      const userlink = `/admin/employee/view/${empId}`;
      window.location.href = userlink;
    },
  },
  filters: {
    momentDate(date) {
      return moment(date).format('DD');
    },
    momentDay(date) {
      return moment(date).format('dddd').toLowerCase();
    },
    momentMoYear(date) {
      return moment(date).format('MMM YYYY');
    },
    moment(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
  },
};
</script>
