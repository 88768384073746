<template>
    <div class="modal modal__loading" v-if="loadingPage">
      <div class="loading__icon">
        <!-- <img :src="loadingimg" alt="loading image"> -->
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
        <div class="loading__block"></div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /* eslint-disable */
  data () {
    return {
      dialog: true,
      loadingimg: '/img/loading.gif',
    }
  },
  computed: {
    ...mapGetters([
      'loadingPage',
    ]),
  },
  mounted() {
    setTimeout(() => {
      this.loadingPage;
    }, 500);
  }
}
</script>

<style lang="scss">
.loading__icon {
  max-width: 80px !important;
  width: 100%;
  margin: 0 auto;
}

.loading__block {
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: 0 10px 10px 0;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background: #FFF;
}

.loading__block:nth-child(4n+1) { animation: wave 2s ease .0s infinite; }
.loading__block:nth-child(4n+2) { animation: wave 2s ease .2s infinite; }
.loading__block:nth-child(4n+3) { animation: wave 2s ease .4s infinite; }
.loading__block:nth-child(4n+4) { animation: wave 2s ease .6s infinite; margin-right: 0; }

@keyframes wave {
  0%   { top: 0;     opacity: 1; }
  50%  { top: 30px;  opacity: .2; }
  100% { top: 0;     opacity: 1; }
}
</style>