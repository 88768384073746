<template>
  <div class="admin-home row">
    <div class="container-left col-xs-12 col-md-9">
      <AdminHeader></AdminHeader>
      <div class="admin-home__links">
        <div class="admin-addnew">
          <div class="admin-addnew__first">
            <p class="admin-addnew__txt"><span class="font-bold">Create</span><br>new employee</p>
            <div class="img__avatar-div">
              <img :src="avatarblank" alt="avatar blank" class="img__avatar">
            </div>
          </div>
          <div class="admin-addnew__second">
            <router-link :to="{ name: 'admin-create-new'}" class="btn__white">Get Started</router-link>
          </div>
        </div>
        <div class="admin-download">
          <div class="admin-addnew__first">
            <p class="admin-addnew__txt"><span class="font-bold">Download</span><br>file</p>
            <div class="img__avatar-div">
              <img :src="iconzip" alt="avatar blank" class="img__avatar">
            </div>
          </div>
          <div class="admin-addnew__second">
            <a :href="links.download_all" class="btn__white" target="blank">Download</a>
          </div>
        </div>
      </div>
      <OnboardList></OnboardList>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import OnboardList from '../../components/Admin/OnboardList.vue';
import Sidebar from '../../components/Admin/Sidebar.vue';
import AdminHeader from '../../components/Admin/Header.vue';

export default {
  name: 'Home',
  data() {
    return {
      // image
      avatarblank: '/img/icons/web/avatar-blank.svg',
      iconzip: '/img/icons/web/zip.svg',
    };
  },
  computed: {
    ...mapGetters([
      'links',
    ]),
  },
  components: {
    AdminHeader,
    OnboardList,
    Sidebar,
  },
};
</script>
