import Vue from 'vue';
import { LoaderPlugin } from 'vue-google-login';
import axios from 'axios';
import applocalstorage from '../../utils/applocalstorage';

Vue.use(LoaderPlugin, {
  client_id: process.env.VUE_APP_GOOGLE_API,
});

const data = {
  adminDetails: {
    isLogin: false,
    first_name: '',
    role: '',
    email: '',
  },
};

const localStorageService = applocalstorage.getService();

export const getters = {
  adminIsLogin: (state) => state.adminDetails.isLogin,
  adminFirstName: (state) => state.adminDetails.first_name,
  adminRole: (state) => state.adminDetails.role,
  adminEmail: (state) => state.adminDetails.email,
};

export const mutations = {
  isLoggedIn: () => {
    // let statusLogin;
    // let adminName;
  },
  setAdminState: (state, payload) => {
    state.adminDetails.isLogin = payload.isSignedIn;
    state.adminDetails.first_name = payload.adminName;
    state.adminDetails.email = payload.email;
    state.loadingPage = false;
  },
  resetAdminState: (state) => {
    state.adminDetails.isLogin = false;
    state.adminDetails.first_name = '';
    state.adminDetails.role = '';
    state.adminDetails.email = '';
  },
  setRole: (state, payload) => {
    state.adminDetails.role = payload;
    // console.log('setAdminRole', payload);
    localStorageService.setAdminRole(payload);
    // console.log('getAdminRole', localStorageService.getAdminRole());
  },
};
export const actions = {
  isLoggedIn({ commit }) {
    commit('isLoggedIn');
  },
  resetAdminState({ commit }) {
    commit('resetAdminState');
  },
  setRole({ commit }, payload) {
    commit('setRole', payload);
  },
  setAdminState({ commit }, payload) {
    commit('setAdminState', payload);
  },
  processGoogle({ dispatch }) {
    dispatch('resetAdminState');
    dispatch('loadingTrue');
    // console.log('login to google');
    // actual submission of data
    return Vue.GoogleAuth.then((auth2) => {
      dispatch('loadingFalse');
      // console.log(`google login status ${auth2.isSignedIn.get()}`);
      // save login status
      if (auth2.isSignedIn.get()) {
        // console.log('login true');
        // console.log('getBasicProfile():', auth2.currentUser.get().getBasicProfile());
        dispatch('setAdminState', {
          isSignedIn: auth2.isSignedIn.get(),
          adminName: auth2.currentUser.get().getBasicProfile().getGivenName(),
          email: auth2.currentUser.get().getBasicProfile().getEmail(),
        });
      } else {
        // reset admin state
        dispatch('resetAdminState');
      }
    });
  },
  googleSignIn({ dispatch }) {
    // pass request and prepare to submit data
    return new Promise((resolve, reject) => {
      dispatch('processGoogle').then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        },
      );
    });
  },
  processGoogleSignOut({ dispatch }) {
    dispatch('resetAdminState');
    dispatch('loadingTrue');
    // actual submission of data
    return Vue.GoogleAuth.then((auth2) => {
      dispatch('loadingFalse');
      auth2.signOut();
      // save login status
      if (auth2.isSignedIn.get()) {
        dispatch('setAdminState', {
          isSignedIn: auth2.isSignedIn.get(),
          adminName: auth2.currentUser.get().getBasicProfile().getGivenName(),
          email: auth2.currentUser.get().getBasicProfile().getEmail(),
        });
      } else {
        // reset admin state
        dispatch('resetAdminState');
      }
    });
  },
  googleSignOut({ dispatch }) {
    // pass request and prepare to submit data
    return new Promise((resolve, reject) => {
      dispatch('processGoogleSignOut').then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        },
      );
    });
  },
  checkRole({ dispatch }) {
    dispatch('loadingTrue');
    return new Promise((resolve, reject) => {
      dispatch('googleSignIn').then(() => {
        dispatch('submitEmail').then(
          (response) => {
            resolve(response);
            dispatch('loadingFalse');
          },
          (err) => {
            reject(err);
            dispatch('loadingFalse');
          },
        );
      });
    });
  },
  async submitEmail({ dispatch, rootState }) {
    dispatch('loadingTrue');
    const gEmail = rootState.google.adminDetails.email;

    if (gEmail) {
    // actual submission of data
      const request = {
        email: gEmail,
      };
      // console.log(request);
      try {
        const res = await axios.post(rootState.services.adminloginapi, request, { skipAuthRefresh: true });
        // console.log(res.status);
        // console.log(res);
        const { role } = res.data.result;
        // save admin role to vuex
        dispatch('setRole', role);
        if (role === 'admin') {
          if (res.data.result.auth) {
            dispatch('setUserAuth', {
              access_token: res.data.result.auth.access_token,
              refresh_token: res.data.result.auth.refresh_token,
            });
            dispatch('resetUserProfile');
            dispatch('resetUserInfo');
          }
        } else {
          dispatch('googleSignOut');
        }
        dispatch('loadingFalse');
      } catch (error) {
        // console.log(error.response);
        dispatch('googleSignOut');
        dispatch('loadingFalse');
      }
    } else {
      dispatch('loadingFalse');
    }
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
