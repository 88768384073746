<template>
  <div class="profile">
    <div class="text-right">
        <router-link :to="{ name: 'admin-update-employee'}" class="profile__link ma-0">edit profile</router-link>
    </div>
    <div class="row">
        <div class="profile__head col-sm-7 d-flex">
            <img :src=this.userimg alt="profile image" class="profile__img mr-7">
            <div class="profile__details">
                <p class="ma-0 profile__fname">{{user.first_name}}</p>
                <p class="ma-0 profile__name">{{user.last_name}}, {{user.first_name}} {{user.middle_initial}}</p>
                <p class="ma-0 profile__position mb-10">{{user.position}}</p>
                <p class="ma-0 profile__tstart">Target Start Date: <span>{{user.target_start_date | momentFull }}</span></p>
            </div>
        </div>
        <div class="profile__progress col-sm-5">
          <div class="d-flex align-end justify-space-between profile__progress--desc">
            <p class="ma-0 progress__txt--black">complete</p>
            <p class="ma-0 progress__txt f-color--light-black">{{Number(progress).toLocaleString()}}%</p>
          </div>
            <v-progress-linear
              v-model="progress"
              color="#71C5F0"
              class="progress"
               :class="{'progress__inline--20': progress < 30,'progress__inline--50': progress > 30 && progress < 60,'progress__inline--100': progress > 60}"
            ></v-progress-linear>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';

export default {
  props: ['user'],
  name: 'UserProfile',
  data() {
    return {
      progress: this.user.progress,
      userimg: '/img/profile-default.png',
    };
  },
  components: {
  },
  filters: {
    momentFull(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
  },
};
</script>
